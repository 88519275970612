<template>
  <b-field
    :label="label"
    addons
  >
    <b-taginput
      ref="ingredient"
      v-model="innerValues"
      v-sortable="sortableOptions"
      class="ingredient-sortable control"
      open-on-focus
      :data="filteredData"
      autocomplete
      field="label"
      :keep-first="false"
      expanded
      ellipsis
      :disabled="disabled"
      :placeholder="innerValues.length == 0 ? placeholder : ''"
      :loading="loading"
      @remove="obj => addToFilteredData(obj)"
      @add="obj => addedItem(obj)"
      @typing="getFilteredData"
    >
      <template slot-scope="props">
        <span
          :title="props.option.label"
          v-html="props.option.label"
        />
      </template>
      <template
        slot="selected"
        slot-scope="props"
      >
        <b-tag
          v-for="(tag, index) in props.tags"
          :key="tag.id + (tag.val_id ? tag.val_id : '')"
          :tabstop="false"
          :title="tag.label"
          :ellipsis="ellipsis"
          attached
          :closable="closable && !disabled"
          class="is-clickable"
          @close.prevent="$refs.ingredient.removeTag(index, $event)"
        >
          <span v-html="tag.label" />
        </b-tag>
      </template>
    </b-taginput>
    <p
      v-if="$can('create', 'catalog.nutrifacts')"
      v-show="!loading && filteredData.length == 0"
      class="control"
    >
      <b-button
        :disabled="disabled"
        class="button"
        @click="clickAdd"
      >
        <b-icon
          icon="plus"
          type="is-grey-light"
        />
      </b-button>
    </p>
  </b-field>
</template>

<script>
import debounce from "debounce";
import Sortable from "sortablejs";

const createSortable = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options,
    onEnd: function(evt) {
      const data = vnode.componentInstance.$data.tags;
      const item = data[evt.oldIndex];
      if (evt.newIndex > evt.oldIndex) {
        for (let i = evt.oldIndex; i < evt.newIndex; i++) {
          data[i] = data[i + 1];
        }
      } else {
        for (let i = evt.oldIndex; i > evt.newIndex; i--) {
          data[i] = data[i - 1];
        }
      }
      data[evt.newIndex] = item;
      vnode.componentInstance.$emit("input", data);
    }
  });
};

/**
 * We add a new instance of Sortable when the element
 * is bound or updated, and destroy it when it's unbound.
 */
const sortable = {
  name: "sortable",
  bind(el, binding, vnode) {
    const container = el.querySelector(".taginput-container");
    container._sortable = createSortable(container, binding.value, vnode);
  },
  update(el, binding, vnode) {
    const container = el.querySelector(".taginput-container");
    container._sortable.destroy();
    container._sortable = createSortable(container, binding.value, vnode);
  },
  unbind(el) {
    const container = el.querySelector(".taginput-container");
    container._sortable.destroy();
  }
};

export default {
  directives: { sortable },

  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [Array],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    ellipsis: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sortableOptions: {
        chosenClass: "is-primary",
        draggable: ".tags"
      },
      loading: false,
      innerValues: this.value,
      filteredData: this.value || [],
      text: "",
      placeholder: ""
    };
  },
  watch: {
    innerValues(newVal) {
      let idName = "id";
      if (["direction", "warning"].indexOf(this.type) != -1) idName = "val_id";
      let innerValues = Array.from(new Set(newVal.map(a => a[idName]))).map(
        id => {
          return newVal.find(a => a[idName] == id);
        }
      );
      if (innerValues.length != newVal.length) {
        this.innerValues = innerValues;
      } else this.$emit("input", newVal);
    }
  },
  mounted() {
    this.placeholder = this.placeholders.nutrifacts[this.type];
  },
  methods: {
    clickAdd() {
      this.$emit("click-add");
    },
    clickSubmit() {
      this.$emit("click-submit");
    },
    addedItem(obj) {
      if (this.type == "name") {
        this.innerValues = [obj];
      }
    },
    addToFilteredData(obj) {
      if (this.filteredData.findIndex(f => f.id == obj.id) == -1)
        this.filteredData.unshift(obj);
    },
    getFilteredData: debounce(function(text) {
      this.text = text;
      if (text.length) {
        this.loading = true;
        this.$axios
          .get(`catalog/nutrifacts/search/${this.type}/${text}`)
          .then(res => {
            this.filteredData = res.data;
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.loading = false));
      } else {
        this.filteredData = [];
      }
    }, 200)
  }
};
</script>
