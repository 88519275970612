<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :vid="vid"
    :name="$attrs.name || label"
    :rules="rules"
    :mode="validationMode"
  >
    <b-field
      :label="label"
      :disabled="disabled"
      :type="{
        'is-default': disabled,
        'is-danger': errors[0],
      }"
      :message="errors || message"
    >
      <b-taginput
        :ref="refValue"
        v-model="innerValue"
        v-sortable="sortableOptions"
        :class="sortable ? 'taginput-sortable' : ''"
        :data="filteredData"
        :disabled="disabled"
        autocomplete
        :open-on-focus="openOnFocus"
        :allow-new="allowNew"
        :field="field"
        :placeholder="placeholder"
        @typing="getFilteredTags"
        @blur="addTemp"
      >
        <template
          slot="selected"
          slot-scope="props"
        >
          <b-tag
            v-for="(tag, index) in props.tags"
            :key="tag"
            attached
            :tabstop="false"
            :closable="closable"
            @close.prevent="$refs[refValue].removeTag(index, $event)"
          >
            {{ !allowNew ? getValue(tab) : tag }}
            <span
              v-if="!disabled"
              @click="editable && editKp(tag, index)"
            >
              <b-icon
                size="is-small"
                class="is-clickable mt-1 ml-2"
                icon="pencil"
              />
            </span>
          </b-tag>
        </template>
        <slot />
      </b-taginput>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import Sortable from "sortablejs";

const createSortable = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options,
    onEnd: function(evt) {
      const data = vnode.componentInstance.$data.tags;
      const item = data[evt.oldIndex];
      if (evt.newIndex > evt.oldIndex) {
        for (let i = evt.oldIndex; i < evt.newIndex; i++) {
          data[i] = data[i + 1];
        }
      } else {
        for (let i = evt.oldIndex; i > evt.newIndex; i--) {
          data[i] = data[i - 1];
        }
      }
      data[evt.newIndex] = item;
      vnode.componentInstance.$emit("input", data);
    }
  });
};
/**
 * We add a new instance of Sortable when the element
 * is bound or updated, and destroy it when it's unbound.
 */
const sortable = {
  name: "sortable",
  bind(el, binding, vnode) {
    const container = el.querySelector(".taginput-container");
    container._sortable = createSortable(container, binding.value, vnode);
  },
  update(el, binding, vnode) {
    const container = el.querySelector(".taginput-container");
    container._sortable.destroy();
    container._sortable = createSortable(container, binding.value, vnode);
  },
  unbind(el) {
    const container = el.querySelector(".taginput-container");
    container._sortable.destroy();
  }
};
export default {
  directives: { sortable },
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String,
      default: ""
    },
    refValue: {
      type: String,
      default: "tagInput"
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    data: {
      type: Array,
      required: true
    },
    // must be included in props
    value: {
      type: null,
      default: ""
    },
    placeholder: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    openOnFocus: {
      type: Boolean,
      default: true
    },
    field: {
      type: String,
      required: true
    },
    sortable: {
      type: Boolean,
      default: true
    },
    allowNew: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    },
    validationMode: {
      type: String,
      default: "aggressive"
    }
  },
  data() {
    return {
      sortableOptions: {
        chosenClass: "is-primary",
        draggable: ".tags"
      },
      tempVal: null,
      innerValue: "",
      filteredData: this.data
    };
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      let innerValue = newVal.filter((v, i) => newVal.indexOf(v) === i);
      if (innerValue.length == newVal.length) this.$emit("input", newVal);
      else this.innerValue = innerValue;
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = Array.isArray(this.value) ? this.value : [];
    }
  },
  methods: {
    addTemp() {
      if (this.tempVal && this.tempVal != "") {
        if (Array.isArray(this.innerValue)) {
          this.innerValue.push(this.tempVal);
        } else {
          this.innerValue = [this.tempVal];
        }
        this.tempVal = null;
        this.$refs[this.refValue].newTag = "";
      }
    },
    getFilteredTags(text) {
      this.tempVal = text;
      this.filteredData = this.data.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    getValue(tag) {
      let fields = this.field.split(".");
      let value = tag;
      for (let i = 0; i < fields.length; i++) {
        const f = fields[i];
        value = value[f];
      }
      return value;
    },
    editKp(kp, index) {
      this.$refs[this.refValue].removeTag(index);
      this.tempVal = kp;
      this.$refs[this.refValue].newTag = kp;
    }
  }
};
</script>

<style>
.taginput-sortable .tag {
  cursor: grab !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
</style>
