<template>
  <b-field
    :label="label"
    addons
  >
    <b-autocomplete
      v-model="text"
      :data="filteredData"
      :placeholder="!innerValues.label ? placeholder : ''"
      class="control"
      open-on-focus
      :disabled="disabled"
      expanded
      field="label"
      :loading="loading"
      @typing="getFilteredData"
      @select="option => selectName(option)"
    >
      <template slot-scope="props">
        <span
          :title="props.option.label"
          v-html="props.option.label"
        />
      </template>
    </b-autocomplete>
    <p
      v-if="$can('create', 'catalog.nutrifacts')"
      v-show="!loading && filteredData.length == 0"
      class="control"
    >
      <b-button
        :disabled="disabled"
        class="button"
        @click="clickAdd"
      >
        <b-icon
          icon="plus"
          type="is-grey-light"
        />
      </b-button>
    </p>
  </b-field>
</template>

<script>
import debounce from "debounce";

export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [Array],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    ellipsis: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      innerValues: this.value[0] || {},
      filteredData: this.value ? this.value : [],
      text: this.value[0] ? this.value[0].label : ""
    };
  },
  watch: {
    innerValues(newVal) {
      this.$emit("input", [newVal]);
    },
    value(newVal) {
      if (newVal[0] && this.innerValues.label != newVal[0].label) {
        this.innerValues = newVal[0];
        this.text = newVal[0].label;
        this.filteredData = newVal;
      }
    }
  },
  methods: {
    clickAdd() {
      this.$emit("click-add");
    },
    selectName(option) {
      if (option) this.innerValues = option;
      else this.innerValues = [];
    },
    getFilteredData: debounce(function() {
      let text = this.text;
      if (text.length) {
        this.loading = true;
        this.$axios
          .get(`catalog/nutrifacts/search/${this.type}/${text}`)
          .then(res => {
            this.filteredData = res.data;
            this.$emit("set-names", res.data);
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.loading = false));
      } else {
        this.filteredData = [];
        this.innerValues = [];
      }
    }, 200)
  }
};
</script>
