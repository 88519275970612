<template>
  <Page
    v-if="
      ($can('create', 'catalog') ||
        $can('update', 'catalog') ||
        $can('read', 'catalog')) &&
        !notFound
    "
    :subtitle="subtitle"
    :loading="loading"
    has-actions
  >
    <b-switch
      v-if="product.options"
      slot="actions"
      :disabled="
        (product.options.active && !this.$can('delete', 'catalog')) ||
          (!product.options.active && !this.$can('update', 'catalog')) ||
          action == 'draft'
      "
      :value="product.options.active"
      type="is-success"
      @input="activateOrDesactivateProduct"
    >
      {{ $tc("active", 1) }}
    </b-switch>
    <Card content-class="px-0 py-1">
      <div
        v-if="loading"
        style="min-height: 200px"
      >
        <b-loading
          v-model="loading"
          :is-full-page="false"
        />
      </div>
      <b-tabs
        v-else
        v-model="activeTab"
        expanded
        :class="activeTab == 'analytics' ? 'paddingless' : ''"
      >
        <b-tab-item
          :label="$t('general')"
          :disabled="generalDisabled"
          value="general"
        >
          <General
            :prod-id="prod_id"
            :suppliers="product.suppliers"
            :brands="product.brands"
            :covers="product.covers"
            :warehouses="product.filters.warehouses"
            :noindex="product.options && product.options.noindex"
            :categories="product.categories"
            :sites="product.sites"
            :tlds="product.tlds"
            :action="action"
            @setAttribute="setProductAttribute"
            @changeStep="changeStep"
          />
        </b-tab-item>
        <b-tab-item
          :disabled="optionsDisabled"
          :label="$t('options')"
          value="options"
        >
          <Options
            :key="attribute"
            :prod-id="prod_id"
            :sizes-types="product.sizes_types"
            :colors="product.colors"
            :flavors="product.flavors"
            :sold="
              product.sold &&
                action != 'clone' &&
                !$can('update', 'catalog.override')
            "
            :attributes-props="product.attributes || {}"
            :langs="filters.languages"
            :attributes-types="filters.types"
            :warehouses="product.filters.warehouses"
            :car-categories="product.filters.car_categories"
            :attribute="attribute"
            :action="action"
            :sites="product.sites"
            :tlds="product.tlds"
            :options="product.options"
            :countries="product.countries"
            @changeStep="changeStep"
          />
        </b-tab-item>
        <b-tab-item
          :disabled="PricingDisabled"
          :label="$t('pricing')"
          value="pricing"
        >
          <Pricing
            :key="attribute"
            :prod-id="prod_id"
            :sites="product.sites"
            :tlds="product.tlds"
            :skus="skus"
            :action="action"
            :warehouses="product.filters.warehouses"
            @changeStep="changeStep"
          />
        </b-tab-item>
        <b-tab-item
          :disabled="imagesDisabled"
          :label="$t('images')"
          value="images"
        >
          <Images
            :skus="skus"
            :images="images"
            :action="action"
            :warehouses="product.filters.warehouses"
            @changeStep="changeStep"
          />
        </b-tab-item>
        <b-tab-item
          v-if="action == 'edit' || action == 'read' || action == 'clone'"
          :disabled="BatchesDisabled"
          :label="$tc('batch', 2)"
          value="batches"
        >
          <Batches
            :batches="product_batches"
            :action="action"
            :colors="product.colors"
            :currency="currency"
            :warehouses="product.filters.warehouses"
            :batchesloading="batches_loading"
            :skus="skus"
            @getData="getBatches"            
          />
        </b-tab-item>
        <b-tab-item
          v-if="
            (action == 'edit' || action == 'read') &&
              $can('read', 'orders.analytics')
          "
          :label="$tc('analytic', 2)"
          value="analytics"
        >
          <ranking-view
            v-if="activeTab == 'analytics'"
            class="is-shadowless"
            :total="total"
            :pagination-object="pagination"
            :stats="statsAnalytics"
            :stats-loading="stats_loading"
            :headers="stats_headers"
            :warehouses="warehousesAnalytics"
            :origin-sites="originSites"
            :brands="brands"
            :salesmen="salesmenAnalytics"
            :origin-categories="originCategories"
            :original-data="originalData"
            :prod-id="prod_id"
            @getData="loadAnalyticsData"
          />

          <hr class="has-background-grey-lighter">

          <graphs-component
            v-if="activeTab == 'analytics'"
            class="is-shadowless"
            :stats="data"
            :periods="periods"
            :brands-prop="graphBrands"
            :origin-categories="categories"
            :warehouses="warehouses"
            :origin-sites="sites"
            :salesmen="salesmen"
            :loading-prop="loadingGraph"
            @getData="getData"
          />
        </b-tab-item>
        <b-tab-item
          v-if="
            action != 'create' &&
              action != 'draft' &&
              nutritionalValues &&
              ($can('read', 'catalog.nutrifacts.products') ||
                $can('create', 'catalog.nutrifacts.products') ||
                $can('update', 'catalog.nutrifacts.products'))
          "
          :disabled="nutriFactsDisabled"
          :label="$t('nutritional_values')"
          value="nutritional_values"
        >
          <Nutrifacts
            v-if="activeTab == 'nutritional_values'"
            :data="nutritionalValues"
            :langs="filters.languages"
            :units="filters.units"
            :action="action"
            :skus="skus"
            @refreshNutrifacts="refreshNutrifacts"
          />
        </b-tab-item>
      </b-tabs>
    </Card>
  </Page>
  <NotFoundPage v-else-if="notFound" />
</template>

<script>
import General from "@/components/forms/product/General";
import Options from "@/components/forms/product/Options";
import Pricing from "@/components/forms/product/Pricing";
import Images from "@/components/forms/product/Images";
import Batches from "@/components/forms/product/Batches";
import rankingView from "@/components/modules/analytics/ranking/index";
import graphsComponent from "@/components/modules/analytics/graphs/index";
import Nutrifacts from "@/components/forms/product/Nutrifacts.vue";

import NotFoundPage from "@/components/NotFound.vue";

export default {
  components: {
    General,
    Options,
    Pricing,
    Images,
    Batches,
    rankingView,
    graphsComponent,
    Nutrifacts,
    NotFoundPage
  },
  data() {
    return {
      subtitle: " ",
      loading: true,
      notFound: false,
      action: "",
      generalSaved: false,
      optionsSaved: false,
      activeTab: "general",
      product: {},
      skus: [],
      images: [],
      batches: [],
      product_batches: [],
      prod_id: 0,
      attribute: "",
      nutritionalValues: [],
      filters: {},
      currency: "",
      getbatches: true,
      getnutrifacts: true,
      batches_loading: true,
      initialSorting : "?sorting=stock:desc",

      // Analytics
      total: 0,
      pagination: {
        itemsPerPage: 200
      },
      statsAnalytics: [],
      originalData: [],
      stats_loading: false,
      warehousesAnalytics: [],
      originSites: [],
      brands: [],
      salesmenAnalytics: [],
      originCategories: [],
      stats_headers: [
        {
          text: this.$tc("name", 1),
          value: "tld",
          width: 150
        },
        {
          text: this.$tc("order", 2),
          value: "num_orders"
        },
        {
          text: this.$t("gross_revenue"),
          value: "gross_revenue"
        },
        {
          text: this.$t("discount"),
          value: "discount"
        },
        {
          text: this.$t("net_revenue"),
          value: "net_revenue"
        },
        {
          text: this.$t("cashback"),
          value: "cashback"
        },
        {
          text: this.$t("purchase_price"),
          value: "purchase_price"
        },
        {
          text: this.$t("gross_margin"),
          value: "gross_margin"
        },
        {
          text: this.$t("gross_margin_ratio"),
          value: "gross_margin_ratio"
        },
        {
          text: this.$t("avg_cart"),
          value: "avg_cart"
        },
        {
          text: this.$t("qty"),
          value: "quantity"
        },
        {
          text: this.$t("qty_free"),
          value: "quantity_free"
        },
        {
          text: "ABC",
          value: "abc",
          align: "center"
        }
      ],

      // graphs
      loadingGraph: true,
      warehouses: [],
      countries: {
        list: [],
        selected: null,
      },
      sites: [],
      graphBrands: [],
      categories: [],
      salesmen: [],
      data: [],
      periods: []
    };
  },
  computed: {
    generalDisabled() {
      return (
        (this.action != "edit" && (this.generalSaved || this.optionsSaved)) 
        
        //|| this.action == "clone"
      );
    },
    optionsDisabled() {
      return (
        ((this.action == "create" || this.action == "clone") && !this.generalSaved) ||
        (this.action != "edit" && this.optionsSaved)
      );
    },
    PricingDisabled() {
      return (
        ["clone", "draft", "create"].indexOf(this.action) != -1 &&
        !this.optionsSaved
      );
    },
    imagesDisabled() {
      return (
        ["clone", "draft", "create"].indexOf(this.action) != -1 &&
        !this.optionsSaved
      );
    },
    nutriFactsDisabled() {
      return (
        ["clone", "draft", "create"].indexOf(this.action) != -1 &&
        !this.optionsSaved
      );
    },
    BatchesDisabled() {
      return (
        this.action == "clone" &&
        !this.optionsSaved
      );
    },
  },
  watch: {
    activeTab() {
      if (this.activeTab === "batches" && this.getbatches)
      this.getBatches("");
      else if (this.activeTab === "nutritional_values" && this.getnutrifacts)
      this.getNutrifacts("catalog/sku/" + this.$route.params.sku + "/nutrifacts");
    },
  },
  created() {
    let title = "";
    if(this.$route.params.sku) this.skus = [this.$route.params.sku];
    if (this.$can("create", "catalog") || this.$can("update", "catalog") ||
    this.$can("create", "catalog.warehouses") || this.$can("update", "catalog.warehouses") ||
    this.$can("create", "catalog.pricing") || this.$can("update", "catalog.pricing")) {
      if (this.$route.params.sku) {
        if (this.$route.params.action == "clone") {
          this.action = "clone";
          this.activeTab = "general";
          title = `${this.$t("duplicate")} ${this.$tc("product", 1)}`;
          this.getProduct("catalog/sku/" + this.$route.params.sku);
        } else if (this.$route.params.action == "draft") {
          this.action = "draft";
          title = `${this.$t("complete")} ${this.$tc("product", 1)}`;
          this.getProduct("catalog/product/" + this.$route.params.sku);
        } else {
          this.action = "edit";
          this.activeTab = "options";
          title = `${this.$t("edit")} ${this.$tc("product", 1)}`;
          this.getProduct("catalog/sku/" + this.$route.params.sku);
        }
      } else {
        this.action = "create";
        title = this.$t("create_product");
        this.getProduct("catalog/product");
      }
    } else if (this.$can("read", "catalog")) {
      this.action = "read";
      title = this.$t("view");
      this.getProduct("catalog/sku/" + this.$route.params.sku);
    }
    this.updateTitle(title);
  },
  methods: {
    activateOrDesactivateProduct() {
      if (this.product.options.active) {
        this.$axios
          .delete("catalog/sku/" + this.skus[0])
          .then(() => {
            this.product.options.active = false;
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
          .catch(e => this.clientError(e));
      } else {
        this.$axios
          .patch("catalog/sku/" + this.skus[0] + "/restore")
          .then(() => {
            this.product.options.active = true;
            this.$root.notify(this.$t("successfully_restored"), "is-success");
          })
          .catch(e => this.clientError(e));
      }
    },
    setProductAttribute(attribute) {
      this.attribute = attribute;
    },
    changeStep({ step, prod_id, skus, generalSaved, optionsSaved }) {
      if (this.action != "edit") {
        this.activeTab = step;
      }
      if (prod_id) this.prod_id = prod_id;
      if (skus) this.skus = skus;
      if (optionsSaved) this.optionsSaved = true;
      if (generalSaved) this.generalSaved = true;
    },
    getProduct(url) {
      this.loading = true;
      this.$axios
        .get(url)
        .then(res => {
          this.product = res.data;
          if (this.action == "clone") 
            this.product.options.active = false;
          let i = res.data.sites.findIndex(p => p.label == "Optigura"); 
          let firstSite = res.data.sites[i];          
          let j = firstSite.langs.findIndex(p => p.iso == "fr"); 
          let product_name = firstSite.langs[j]["name"];
          this.subtitle = product_name;
          if (res.data.options.warehouse)
            this.subtitle += ` (${res.data.options.warehouse.label})`;
          if (res.data.colors.selected != null)
            this.nutritionalValues = null;
          if (res.data.prod_id) this.prod_id = res.data.prod_id;
          if (res.data.images.length && this.action !== "clone") this.images = res.data.images;
          this.countries = res.data.countries;
          this.filters = res.data.filters;
          let index = res.data.options.warehouse.id;
          this.currency = res.data.filters.warehouses[index-1].currency.symbol;
         
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code == 404) {
            this.notFound = true;
          }
          this.clientError(e);
        })
        .finally(() => (this.loading = false));
    },
     getNutrifacts(url) {
      this.loading = true;
      this.$axios
        .get(url)
        .then(res => {          
           this.nutritionalValues = {
              ...res.data.nutrifacts.list,
              infos: res.data.nutrifacts.info,
              importable: res.data.nutrifacts.importable,
            };   
            this.getnutrifacts = false;     
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code == 404) {
            this.notFound = true;
          }
          this.clientError(e);
        })
        .finally(() => (this.loading = false));
    },
    refreshNutrifacts() {
        this.getnutrifacts = true;
    },
     getBatches(params) {
      this.batches_loading = true;
      // let url = "catalog/sku/" + this.$route.params.sku + "/batches" + this.initialSorting;
      let url = "catalog/sku/" + this.skus[0] + "/batches" + this.initialSorting;
      this.$axios
        .get((url) + (params.params ? params.params : ''))
        .then(res => {     
          this.product_batches = res.data.batches;  
          this.getbatches = false; 
          this.initialSorting = "";      
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code == 404) {
            this.notFound = true;
          }
          this.clientError(e);
        })
        .finally(() => (this.batches_loading = false));
    },
    loadAnalyticsData(filters, options, download) {
      this.stats_loading = true;

      // adding selected warehouse to params
      let api_params = "?warehouse_id=" + (options.warehouse_id || "");

      // adding date range to params
      if (options.date_filter.from && options.date_filter.to) {
        api_params +=
          "&date_from=" +
          options.date_filter.from +
          "&date_to=" +
          options.date_filter.to;
      }

      // adding selected ranking value to params
      if (options.selected_ranking)
        api_params += "&ranking=" + options.selected_ranking;

      // adding pagination
      api_params += `&page=${options.pagination.page}&per_page=${options.pagination.perPage}`;

      // adding filters
      let filter = [
        {
          id: "skus",
          selected: [this.sku]
        }
      ];
      if (filters.brands.length) {
        filter.push({
          id: "brands",
          selected: filters.brands
        });
      }
      if (filters.sites.length) {
        let sites = [];
        let slds = [];
        for (let f of filters.sites) {
          if(typeof f == 'number') sites.push(f);
          else slds.push(parseInt(f.substring(1)));
        }
        if (sites.length) {
          filter.push({
            id: "sites",
            selected: sites
          });
        }
        if (slds.length) {
          filter.push({
            id: "slds",
            selected: slds
          });
        }
      }
      if (filters.slds.length) {
        filter.push({
          id: "slds",
          selected: filters.slds
        });
      }
      if (filters.salesmen) {
        filter.push({
          id: "salesmen",
          selected: [filters.salesmen]
        });
      }
      if (filters.categories.length) {
        filter.push({
          id: "categories",
          selected: filters.categories
        });
      }
      // encoding filter values
      api_params += `&filters=${btoa(JSON.stringify(filter))}`;

      if (download) {
        api_params += `&export=1`;
      }

      this.$axios
        .get(`catalog/products/${this.prod_id}/analytics` + api_params)
        .then(res => {
          if (download) {
            // downloading recieved file to user machine
            const url = window.URL.createObjectURL(
              new Blob([res.data.file], {
                type: "application/vnd.ms-excel"
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", res.data.filename);
            document.body.appendChild(link);
            link.click();
          } else {
            // applying new data recieved from API
            let data = res.data.list;
            this.warehousesAnalytics = res.data.filters.warehouses;
            this.brands = res.data.filters.brands.filter(
              b => b.id == this.product.brands.selected
            );
            this.originCategories = res.data.filters.categories;
            this.originSites = res.data.filters.sites;
            this.salesmenAnalytics = res.data.filters.salesmen;
            this.originalData = data;
            this.total = res.data.pagination.total;
            this.pagination.page = res.data.pagination.current;
            let summary = res.data.summary;
            let stats = [];

            for (let i = 0; i < data.length; i++) {
              let elt = data[i];
              let obj = {
                id: elt.id,
                label: elt.label,
                num_orders: elt.stats.num_orders,
                gross_revenue: elt.stats.gross_revenue.formatted,
                discount: elt.stats.discount.formatted,
                net_revenue: elt.stats.net_revenue.formatted,
                cashback: elt.stats.cashback.formatted,
                avg_cart: elt.stats.avg_cart.formatted,
                quantity: elt.stats.quantity,
                abc: elt.stats.abc
              };
              if (elt.stats.purchase_price)
                obj.purchase_price = elt.stats.purchase_price.formatted;
              if (elt.stats.gross_margin)
                obj.gross_margin = elt.stats.gross_margin.formatted;
              if (elt.stats.gross_margin_ratio)
                obj.gross_margin_ratio = elt.stats.gross_margin_ratio;
              stats.push(obj);
            }
            let sum = {
              id: "summary",
              label: this.$t("total"),
              num_orders: summary.num_orders,
              gross_revenue: summary.gross_revenue.formatted,
              discount: summary.discount.formatted,
              net_revenue: summary.net_revenue.formatted,
              cashback: summary.cashback.formatted,
              avg_cart: summary.avg_cart.formatted,
              quantity: summary.quantity,
              abc: summary.abc
            };
            if (summary.purchase_price)
              sum.purchase_price = summary.purchase_price.formatted;
            if (summary.gross_margin)
              sum.gross_margin = summary.gross_margin.formatted;
            if (summary.gross_margin_ratio)
              sum.gross_margin_ratio = summary.gross_margin_ratio;
            stats.push(sum);
            this.statsAnalytics = JSON.parse(JSON.stringify(stats));
          }
        })
        .catch(e => {
          this.valid_account = false;
          this.clientError(e);
        })
        .finally(() => (this.stats_loading = false));
    },
    getData(obj) {
      this.loadingGraph = true;
      let api_params = `?warehouse_id=${obj.warehouse_id || ""}&period=${
        obj.selected_date_mode
      }`;

      let filter = [
        {
          id: "skus",
          selected: [this.sku]
        }
      ];
      if (obj.brands && obj.brands.length) {
        filter.push({
          id: "brands",
          selected: obj.brands
        });
      }
      if (obj.sites && obj.sites.length) {
        filter.push({
          id: "sites",
          selected: obj.sites
        });
      }
      if (obj.tlds && obj.tlds.length) {
        filter.push({
          id: "slds",
          selected: obj.tlds
        });
      }
      if (obj.categories && obj.categories.length) {
        filter.push({
          id: "categories",
          selected: obj.categories
        });
      }
      if (obj.salesmen && obj.salesmen.length) {
        filter.push({
          id: "salesmen",
          selected: obj.salesmen
        });
      }
      let dates_array = [
        {
          from: obj.date.from,
          to: obj.date.to
        }
      ];
      if (obj.compare_date && obj.compare_date.from && obj.compare_date.to) {
        dates_array.push({
          from: obj.compare_date.from,
          to: obj.compare_date.to
        });
      }
      filter.push({
        id: "dates",
        selected: dates_array
      });

      api_params += `&filters=${btoa(JSON.stringify(filter))}`;

      this.$axios
        .get("analytics/graph" + api_params)
        .then(res => {
          this.periods = res.data.dataset.periods;
          this.data = res.data.dataset.data;
          this.warehouses = res.data.filters.warehouses;
          this.sites = res.data.filters.sites;
          this.graphBrands = res.data.filters.brands.filter(
            b => b.id == this.product.brands.selected
          );
          this.categories = res.data.filters.categories;
          this.salesmen = res.data.filters.salesmen;
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loadingGraph = false));
    }
  }
};
</script>
