<template>
  <div
    v-if="$can('read', 'catalog.nutrifacts.products')"
    class="columns is-mobile is-multiline"
  >
    <div class="column is-12">
      <div class="columns is-mobile is-multiline mt-0">
        <template v-for="info in infos">
          <b-input-validation
            :key="info.id"
            v-model="info.value"
            step=".01"
            :disabled="disabledNutrifacts"
            class="column is-3-tablet is-6-mobile"
            :label="info.label"
            :placeholder="placeholders.number['1']"
            input-type="number"
            :suffix="info.unit"
            hide-details
          />
        </template>
      </div>
    </div>
    <div class="column is-6-tablet is-12-mobile">
      <b-field
        :label="$tc('label_language', 2)"
        addons
      >
        <b-taginput
          ref="langagues"
          v-model="selected_langs"
          open-on-focus
          :disabled="disabledNutrifacts"
          :data="filteredLangs"
          autocomplete
          field="label"
          expanded
          ellipsis
          :loading="downloadingNutrifact"
          :placeholder="selected_langs.length == 0 ? $t('choose') : ''"
          @add="getFilteredLangs"
          @remove="getFilteredLangs"
          @typing="getFilteredLangs"
        >
          <template slot-scope="props">
            <span v-html="props.option.label" />
          </template>
          <template
            slot="selected"
            slot-scope="props"
          >
            <b-tag
              v-for="(tag, index) in props.tags"
              :key="tag.id"
              :tabstop="false"
              ellipsis
              attached
              :closable="!disabledNutrifacts"
              class="is-clickable"
              @close.prevent="$refs.langagues.removeTag(index, $event)"
            >
              {{ tag.label }}
            </b-tag>
          </template>
          <template slot="empty">
            {{ $t("no_result_found") }}
          </template>
        </b-taginput>
      </b-field>
    </div>
    <div class="column is-6-tablet is-12-mobile">
      <NameAutocomplete
        v-model="selected_name"
        :disabled="disabledNutrifacts"
        :placeholder="placeholders.nutrifacts.name"
        :label="$tc('name')"
        type="name"
        @click-add="showAddIngredient('name')"
        @set-names="setNames"
      />
    </div>
    <div class="column is-6-tablet is-12-mobile">
      <sortable-tag-input
        v-if="renderComponent"
        v-model="selected_allergen"
        :disabled="disabledNutrifacts"
        type="allergen"
        :label="$tc('allergen', 2)"
        @click-add="showAddIngredient('allergen')"
      />
    </div>
    <div class="column is-6-tablet is-12-mobile">
      <sortable-tag-input
        v-if="renderComponent"
        v-model="selected_direction"
        :disabled="disabledNutrifacts"
        type="direction"
        :label="$t('directions')"
        @click-add="showAddIngredient('direction')"
      />
    </div>
    <div class="column is-12">
      <sortable-tag-input
        v-if="renderComponent"
        v-model="selected_ingredient"
        :disabled="disabledNutrifacts"
        :label="$tc('ingredient', 2)"
        type="ingredient"
        @click-add="showAddIngredient('ingredient')"
      />
    </div>
    <div class="column is-6-12">
      <sortable-tag-input
        v-if="renderComponent"
        v-model="selected_warning"
        :disabled="disabledNutrifacts"
        :label="$tc('warning', 2)"
        type="warning"
        @click-add="showAddIngredient('warning')"
      />
    </div>

    <div class="column is-12">
      <b-button
        type="is-primary"
        class="mr-4"
        :disabled="disabledNutrifacts || attributesSaveLoading"
        :loading="attributesSaveLoading"
        @click="submitAttributes"
      >
        {{ $t("save") }}
      </b-button>

      <b-dropdown
        aria-role="list"
        position="is-bottom-left"
        scrollable
        max-height="400px"
        class="mr-2"
      >
        <b-button slot="trigger">
          {{ $t("download") }}
          <b-icon
            icon="chevron-down"
            size="is-small"
            class="ml-1"
          />
        </b-button>

        <b-dropdown-item
          v-for="lang in langs"
          :key="lang.iso"
          aria-role="listitem"
          @click="downloadNutrifacts(lang)"
        >
          <img
            :src="'/img/langs/' + lang.iso + '.svg'"
            width="18"
            height="18"
            class="mr-1"
          >
          {{ lang.label }}
        </b-dropdown-item>
      </b-dropdown>

      <!-- import button -->
      <b-dropdown
        aria-role="list"
        position="is-bottom-left"
        scrollable
        max-height="400px"
      >
        <b-button slot="trigger">
          {{ $t("import") }}
          <b-icon
            icon="chevron-down"
            size="is-small"
            class="ml-1"
          />
        </b-button>

        <b-dropdown-item
          v-for="item in importable"
          :key="item.key"
          aria-role="listitem"
          @click="importNutrifacts(item)"
        >
          {{ item.label }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="column is-12">
      <hr class="m-0">
    </div>
    <!-- values -->
    <div class="column is-6-tablet is-12-mobile">
      <p class="is-size-5 mb-1">
        <b>{{
          valueFor.value
            ? `${$tc("value_for", 2)} ${valueFor.value} ${valueFor.unit}`
            : $tc("value", 2)
        }}</b>
      </p>
      <b-table
        :data="list"
        class="v-align-middle"
        :row-class="
          (row, index) => (row.hasBorderBottom ? 'border-thick-bottom' : '')
        "
      >
        <b-table-column
          v-slot="props"
          field="label"
          :label="$t('nutrition_declaration')"
        >
          <span :class="{ 'ml-4': !props.row.isParent }">
            {{ props.row.label }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          width="120"
          field="nutrifact"
          :label="$t('quantity')"
        >
          <b-input-validation
            :ref="`nutrifact_${props.row.id}`"
            v-model="props.row.nutrifact.value"
            :input-ref="`nutrifact_${props.row.id}`"
            :disabled="disabledNutrifacts"
            size="is-small"
            step=".01"
            :placeholder="placeholders.nutrifacts.nrv"
            input-type="number"
            :suffix="props.row.unit"
            hide-details
            @keyup.enter.native="submitMicro()"
          />
        </b-table-column>
        <b-table-column
          v-slot="props"
          width="150"
          field="nrv"
          :label="`% ${$t('ri')}`"
          numeric
        >
          {{ (props.row.nrv && props.row.nrv.ri) || "-" }}
        </b-table-column>
        <EmptyBlock
          slot="empty"
          icon="barcode"
        />
        <div
          slot="footer"
          class="mt-3 is-flex is-justify-content-space-between"
        >
          <b-autocomplete-validation
            :key="addedMicro"
            v-model="addedMicro"
            :disabled="disabledNutrifacts"
            size="is-small"
            style="width: 250px"
            class="has-text-weight-normal"
            :label="$t('add')"
            :has-add-button="$can('create', 'catalog.nutrifacts')"
            hide-details
            clear-on-select
            :data="micro.filter(item => item.isParent)"
            :placeholder="placeholders.nutrifacts.micro"
            item-text="label"
            field="id"
            @click-add="addNewMicro('micro')"
          />

          <b-button
            class="button"
            size="is-small"
            :disabled="disabledNutrifacts || microSaveLoading"
            :loading="microSaveLoading"
            type="is-primary"
            @click="submitMicro"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </b-table>
    </div>
    <!-- aminogram -->
    <div class="column is-6-tablet is-12-mobile">
      <p class="is-size-5 mb-1">
        <b>{{ $tc("aminogram", 2) }}</b>
      </p>
      <b-table
        class="v-align-middle"
        :data="amino"
      >
        <b-table-column
          v-slot="props"
          field="label"
          :label="$t('nutrition_declaration')"
        >
          <span>{{ props.row.label }}</span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          width="120"
          field="nutrifact"
          :label="$t('quantity')"
        >
          <b-input-validation
            v-model="props.row.nutrifact.value"
            :disabled="disabledNutrifacts"
            size="is-small"
            :placeholder="placeholders.nutrifacts.nrv"
            input-type="number"
            step=".01"
            :suffix="props.row.unit"
            hide-details
            @keyup.enter.native="submitAmino()"
          />
        </b-table-column>
        <EmptyBlock
          slot="empty"
          icon="barcode"
        />
        <div
          slot="footer"
          class="my-2 is-flex is-justify-content-space-between"
        >
          <b-button
            v-if="$can('create', 'catalog.nutrifacts')"
            :disabled="disabledNutrifacts"
            size="is-small"
            outlined
            @click="addNewMicro('amino')"
          >
            <b-icon
              icon="plus"
              size="is-small"
              type="is-grey-light"
            />
          </b-button>
          <b-button
            :disabled="disabledNutrifacts || aminoSaveLoading"
            :loading="aminoSaveLoading"
            class="button"
            size="is-small"
            type="is-primary"
            @click="submitAmino"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </b-table>
    </div>

    <b-modal
      v-model="newIngredient"
      :width="520"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("add") }} {{ newIngredientTitle }}</b>
          </div>
          <hr class="my-0">
        </div>
        <IngredientForm
          class="pt-4"
          :langs="langs"
          :units="units"
          :type="newIngredientType"
          :edit-obj="{}"
          @created="ingredientAdded"
          @close="newIngredient = false"
        />
      </Card>
    </b-modal>
    <b-modal
      v-model="newMicro"
      :width="520"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("add") }} {{ newMicroType }}</b>
          </div>
          <hr class="my-0">
        </div>
        <MicroForm
          class="pt-4"
          :langs="langs"
          :units="units"
          :micro="data.micro"
          :edit-obj="{}"
          :type="newMicroType"
          @created="microAdded"
          @close="newMicro = false"
        />
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway2";
import sortableTagInput from "./SortableTagInput";
import NameAutocomplete from "./NutritionalValue/NameAutocomplete";
import MicroForm from "./NutritionalValue/Micro";
import IngredientForm from "./NutritionalValue/Ingredient";

import debounce from "debounce";

export default {
  components: { sortableTagInput, NameAutocomplete, MicroForm, IngredientForm },
  mixins: [clickaway],
  props: {
    data: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    skus: {
      type: Array,
      required: true
    },
    langs: {
      type: Array,
      required: true
    },
    units: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      downloadingNutrifact: false,
      attributesSaveLoading: false,
      microSaveLoading: false,
      aminoSaveLoading: false,
      inputFocused: null,
      loading: false,
      renderComponent: true,

      // product infos
      amino: this.data.amino,
      micro: [],
      warnings: this.data.warnings || [],
      allergen: this.data.allergen || [],
      infos: this.data.infos,
      ingredients: this.data.ingredient || [],
      directions: this.data.directions || [],
      names: this.data.name || [],
      list: [],
      valueFor: this.data.infos.find(i => i.id == 19),

      // micro
      newMicro: false,
      newMicroType: null,
      addedMicro: null,

      newIngredientType: null,
      newIngredientTitle: null,
      newIngredient: false,

      // langueges
      filteredLangs: [],
      selected_langs: [],
      downloadableLangs: [],

      // allergen
      selected_allergen: this.data.allergen || [],

      // direction
      selected_direction: this.data.directions || [],

      // ingredients
      selected_ingredient: this.data.ingredient || [],

      // warnings
      selected_warning: this.data.warnings || [],

      selected_name: this.data.name || [],
      
      //importables
      importable : this.data.importable,

      nutritionalValues: null,
    };
  },
  computed: {
    disabledNutrifacts() {
      return (
        !(
          this.action != "read" &&
          (this.$can("update", "catalog.nutrifacts.products") ||
            this.$can("create", "catalog.nutrifacts.products"))
        ) ||
        (this.action == "read" &&
          this.$can("read", "catalog.nutrifacts.products"))
      );
    }
  },
  watch: {
    addedMicro(id) {
      this.addMicroToList(id);
    }
  },
  created() {
    this.selected_langs = this.data.langs.filter(l => l.selected);
    this.downloadableLangs = this.selected_langs.reduce(
      (arr, i) => [...arr, i.iso],
      []
    );

    this.filteredLangs = this.data.langs.filter(l => {
      return !l.selected;
    });

    this.setMicro(this.data);
  },
  methods: {
    async forceRerender() {
      this.renderComponent = false;
			// Wait for the change
      await this.$nextTick();
      this.renderComponent = true;
    },
    importNutrifacts(item) {
      let url = "catalog/sku/" + item.id + "/nutrifacts"
      this.loading = true;
      this.$axios
        .get(url)
        .then(res => {          
           if (res.data.nutrifacts) {
             this.nutritionalValues = {
               ...res.data.nutrifacts.list,
               infos: res.data.nutrifacts.info,
             };

              this.amino = this.nutritionalValues.amino;
              this.infos = this.nutritionalValues.infos|| [];
              this.valueFor = this.nutritionalValues.infos.find(i => i.id == 19);
              this.selected_allergen = this.nutritionalValues.allergen|| [];
              this.selected_direction = this.nutritionalValues.directions|| [];
              this.selected_ingredient = this.nutritionalValues.ingredient|| [];
              this.selected_warning = this.nutritionalValues.warnings|| [];
              this.selected_name = this.nutritionalValues.name|| []; 
              this.selected_langs = this.nutritionalValues.langs.filter(l => l.selected);

              this.setMicro(this.nutritionalValues); 
              this.forceRerender();    
           }
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code == 404) {
            this.notFound = true;
          }
          this.clientError(e);
        })
        .finally(() => (this.loading = false));
    },
    downloadNutrifacts(lang) {
      this.downloadingNutrifact = true;
      this.$axios
        .get(
          `catalog/sku/${this.skus.join(",")}/nutrifacts/generate/${lang.iso}`,
          {
            headers: {
              Accept: "application/pdf"
            },
            responseType: "blob"
          }
        )
        .then(res => {
          var pdf_content = res.data;

          var filename = `nutrifacts-${this.skus.join(",")}-${lang.iso}.pdf`;

          var blob = new Blob([pdf_content]);
          if (window.navigator.msSaveOrOpenBlob)
            // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveBlob(blob, filename);
          else {
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob, {
              type: "application/pdf"
            });
            a.download = filename;
            document.body.appendChild(a);
            a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
          }
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.downloadingNutrifact = false));
    },
    addMicroToList(id) {
      if (id && this.list.findIndex(o => o.id == id) == -1) {
        let item = this.micro.find(m => m.id == id);
        this.list.push(item);
        for (let i = 0; i < item.children.length; i++) {
          const child = item.children[i];
          this.list.push(child);
        }
        this.addedMicro = null;
        setTimeout(() => {
          this.$refs[`nutrifact_${item.id}`].$refs[
            `nutrifact_${item.id}`
          ].focus();
        }, 200);
      }
    },
    ingredientAdded(item) {
      if (this.newIngredientType == "ingredient") {
        this.ingredients.unshift(item);
        this.selected_ingredient.push(item);
      } else if (this.newIngredientType == "warning") {
        item.val_id = item.id;
        item.id = item.attribute_id;
        this.selected_warning.push(item);
      } else if (this.newIngredientType == "direction") {
        item.val_id = item.id;
        item.id = item.attribute_id;
        this.selected_direction.push(item);
      } else if (this.newIngredientType == "allergen") {
        this.allergen.unshift(item);
        this.selected_allergen.push(item);
      } else if (this.newIngredientType == "name") {
        this.names.unshift(item);
        this.selected_name = [item];
      }
      this.newIngredient = false;
    },
    microAdded(item) {
      if (this.newMicroType == "micro") {
        if (item.parent_id) {
          let microParentIndex = this.micro.findIndex(
            m => m.id == item.parent_id
          );
          this.micro[microParentIndex].children.push(item);

          let parentIndex = this.list.findIndex(l => l.id == item.parent_id);
          if (parentIndex) {
            this.list.splice(parentIndex + 1, 0, item);
            setTimeout(() => {
              this.$refs[`nutrifact_${item.id}`].$refs[
                `nutrifact_${item.id}`
              ].focus();
            }, 200);
          } else {
            this.addMicroToList(item.parent_id);
          }
        } else {
          item.isParent = true;
          this.micro.push(item);
          this.addMicroToList(item.id);
        }
      } else {
        item.isParent = true;
        this.list.push(item);
        this.amino.push(item);
        setTimeout(() => {
          this.$refs[`nutrifact_${item.id}`].$refs[
            `nutrifact_${item.id}`
          ].focus();
        }, 200);
      }
      this.newMicro = false;
    },
    getFilteredLangs(text) {
      if (typeof text == "object") text = "";
      let filteredLangs = this.data.langs.filter(option => {
        return (
          option.label
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });
      this.filteredLangs = filteredLangs.filter(
        l => this.selected_langs.findIndex(s => s.iso == l.iso) == -1
      );
    },
    // Open Forms & show inputs Functions
    addNewMicro(type) {
      this.newMicroType = type;
      this.newMicro = true;
    },
    inputAway(value) {
      if (this.inputFocused == value) {
        this.inputFocused = null;
      }
    },
    showAddIngredient(type) {
      this.newIngredientType = type;
      this.newIngredientTitle = this.$tc(type, 1).toLowerCase();
      this.newIngredient = true;
    },
    setNames(data){
      this.names = data;
    },
    // Update Functions
    submitAttributes: debounce(function() {
      // this.$emit("refreshNutrifacts");
      let data = [];

      // Setting Attributes Values
      let attrObj = {
        type: "attribute",
        values: []
      };
      for (let i = 0; i < this.infos.length; i++) {
        const info = this.infos[i];
        attrObj.values.push({
          id: info.id,
          value: info.value
        });
      }
      let selected_warning = this.selected_warning.reduce(
        (arr, obj) => [...arr, { id: obj.id, value: obj.val_id }],
        []
      );
      if (selected_warning.length == 0 && this.warnings[0]) {
        selected_warning = [
          {
            id: this.warnings[0].id,
            value: null
          }
        ];
      }
      attrObj.values = [...attrObj.values, ...selected_warning];

      let selected_direction = this.selected_direction.reduce(
        (arr, obj) => [...arr, { id: obj.id, value: obj.val_id }],
        []
      );
      if (selected_direction.length == 0 && this.directions[0]) {
        selected_direction = [
          {
            id: this.directions[0].id,
            value: null
          }
        ];
      }
      attrObj.values = [...attrObj.values, ...selected_direction];
      data.push(attrObj);

      // Setting Langs Values
      let selected_langs = this.selected_langs.reduce(
        (arr, l) => [...arr, l.iso],
        []
      );
      data.push({
        type: "lang",
        values: selected_langs
      });

      // Setting Nutrifacts Values
      let nutrifactObj = {
        type: "nutrifact",
        values: []
      };

      let selected_allergen = this.selected_allergen.reduce(
        (arr, obj) => [...arr, { id: obj.id, value: 1 }],
        []
      );
      if (selected_allergen.length == 0 && this.allergen[0])
        selected_allergen = [{ id: this.allergen[0].id, value: null }];
      nutrifactObj.values = [...selected_allergen];

      let selected_ingredient = this.selected_ingredient.reduce(
        (arr, obj) => [...arr, { id: obj.id, value: 1 }],
        []
      );
      if (selected_ingredient.length == 0 && this.ingredients[0])
        selected_ingredient = [{ id: this.ingredients[0].id, value: null }];

      let selected_name = this.selected_name.reduce(
        (arr, obj) => [...arr, { id: obj.id, value: 1 }],
        []
      );

      if (selected_name[0] && selected_name[0].id == null) {
        selected_name = this.names[0] ? [{ id: this.names[0].id, value: null }] : [];
      }

      nutrifactObj.values = [
        ...nutrifactObj.values,
        ...selected_ingredient,
        ...selected_name
      ];
      if (nutrifactObj.values.length != 0) data.push(nutrifactObj);

      this.attributesSaveLoading = true;
      this.$axios
        .put(`catalog/sku/${this.skus.join(",")}/nutrifacts`, data)
        .then(res => {
          this.$root.notify(this.$t("successfully_updated"), "is-success");
          this.warnings = res.data.warnings || [];
          this.allergen = res.data.allergen || [];
          this.ingredients = res.data.ingredient || [];
          this.names = res.data.name || [];
          this.downloadableLangs = this.selected_langs.reduce(
            (arr, i) => [...arr, i.iso],
            []
          );
          this.attributesSaveLoading = false;
        });
    }, 200),
    submitMicro: debounce(function() {
      this.$emit("refreshNutrifacts");
      let list = this.list.reduce(
        (arr, obj) => [
          ...arr,
          {
            id: obj.id,
            value: obj.nutrifact.value ? parseFloat(obj.nutrifact.value) : null
          }
        ],
        []
      );

      let data = [
        {
          type: "nutrifact",
          values: list
        }
      ];

      this.microSaveLoading = true;
      this.$axios
        .put(`catalog/sku/${this.skus.join(",")}/nutrifacts`, data)
        .then(res => {
          this.amino = res.data.amino;
          this.setMicro(res.data);
          this.$root.notify(this.$t("successfully_updated"), "is-success");
          this.microSaveLoading = false;
        });
    }, 200),
    submitAmino: debounce(function() {
      this.$emit("refreshNutrifacts");
      let list = this.amino.reduce(
        (arr, obj) => [
          ...arr,
          {
            id: obj.id,
            value: obj.nutrifact.value ? parseFloat(obj.nutrifact.value) : null
          }
        ],
        []
      );
      let data = [
        {
          type: "nutrifact",
          values: list
        }
      ];
      this.aminoSaveLoading = true;
      this.$axios
        .put(`catalog/sku/${this.skus.join(",")}/nutrifacts`, data)
        .then(res => {
          this.amino = res.data.amino;
          this.setMicro(res.data);
          this.$root.notify(this.$t("successfully_updated"), "is-success");
          this.aminoSaveLoading = false;
        });
    }, 200),
    // Setting data after update
    setMicro(data) {
      this.list = [];
      for (let i = 0; i < data.macro.length; i++) {
        const element = data.macro[i];
        element.isParent = true;
        this.list = [...this.list, element, ...element.children];
      }
      let lastIndex = this.list.length - 1;
      this.list[lastIndex].hasBorderBottom = true;

      this.micro = data.micro.reduce((arr, obj) => {
        obj.isParent = true;
        for (let i = 0; i < obj.children.length; i++) {
          obj.children[i].parent_id = obj.id;
        }
        return [...arr, obj, ...obj.children];
      }, []);

      for (let i = 0; i < data.micro.length; i++) {
        const item = data.micro[i];
        item.isParent = true;
        let selectedItem = false;
        let selectedChild = false;
        if (
          item.nutrifact.value &&
          this.list.findIndex(o => o.id == item.id) == -1
        ) {
          this.list.push(item);
          selectedItem = true;
        }

        for (let j = 0; j < item.children.length; j++) {
          const child = item.children[j];
          if (selectedItem) {
            this.list.push(child);
          } else if (child.nutrifact.value) {
            if (this.list.findIndex(o => o.id == item.id) == -1) {
              this.list.push(item);
            }
            this.list.push(child);
            selectedChild = true;
          }
        }

        if (!selectedItem && !selectedChild) break;
      }
    }
  }
};
</script>
