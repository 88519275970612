<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile mt-2">
        <div class="column is-7-tablet is-12-mobile">
          <div class="columns is-multiline is-mobile">
            <div class="column is-9-tablet is-7-mobile">
              <ValidationProvider
                v-slot="{ errors }"
                tag="div"
                vid="categories"
                name="categories"
                rules="required"
              >
                <b-field
                  :type="{
                    'is-default': disableInfo,
                    'is-danger': errors[0],
                  }"
                  :message="errors"
                  :label="$tc('category', 1)"
                >
                  <b-taginput
                    ref="cat_tagInput"
                    v-model="categories_selection"
                    :data="filteredCategories"
                    :disabled="disableInfo"
                    autocomplete
                    :open-on-focus="true"
                    field="label"
                    :placeholder="
                      categories_selection.length == 0 ? $t('choose') : ''
                    "
                    @typing="filterCategories"
                  >
                    <template
                      slot="selected"
                      slot-scope="props"
                    >
                      <b-tag
                        v-for="(tag, index) in props.tags"
                        :key="index"
                        :type="main_category === tag.id ? 'is-primary' : ''"
                        :tabstop="false"
                        :closable="!disableInfo"
                        class="is-clickable"
                        @close.prevent="
                          $refs.cat_tagInput.removeTag(index, $event)
                        "
                        @click.native="setMainCategory(tag.id)"
                      >
                        {{ tag.label }}
                      </b-tag>
                    </template>
                  </b-taginput>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-3-talbet is-5-mobile">
              <b-switch
                v-model="noindex_selection"
                class="mt-2"
                :disabled="disableInfo"
              >
                {{ $t("noindex") }}
              </b-switch>
            </div>


           

            <div
              class="column is-6-tablet is-12-mobile"
              :class="covers.length > 0 ? 'is-5-tablet' : 'is-8-tablet'"
            >
              <b-autocomplete-validation
                v-model="brand"
                rules="required"
                :expanded="true"
                :label="$tc('brand', 1)"
                :data="brands.list"
                :placeholder="$t('choose')"
                vid="brand"
                :disabled="disableInfo"
                item-text="label"
                field="id"
              />
            </div>

            <div
              v-if="covers.length"
              class="column is-6-tablet is-12-mobile"
            >
              <b-select-validation
                v-model="cover_id"
                required
                :disabled="disableInfo"
                :label="$t('cover')"
              >
                <template>
                  <option
                    v-for="opt in covers"
                    :key="opt.id"
                    :value="opt.id"
                  >
                    {{
                      opt.label
                    }}
                  </option>
                </template>
              </b-select-validation>
            </div>
          </div>
        </div>

    
        <div class="column is-5-tablet is-12-mobile mt-3">
          <div
            class="columns is-mobile is-multiline is-variable is-0 p-2 bg-cream"
          >
            <div class="column is-12-tablet is-12-mobile">
              <div
                class="columns is-mobile is-multiline is-variable is-2 px-2"
              >
                <div class="column is-12 is-size-5">
                  <b>{{ $tc("vat", 2) }}</b>
                </div>

                <div
                  v-for="(tld, itld) in tlds"
                  :key="itld"
                  class="column is-4-tablet is-6-mobile"
                >
                  <b-select-validation
                    v-model="tld.vats.selected"
                    :rules="tlds_required"
                    :vid="'vat' + itld"
                    :disabled="disableInfo"
                    :label="tld.label"
                    @input="val => selectVat(tld.vats.list.find(v => v.id == val))"
                  >
                    <template>
                      <!-- <option value="">
                          {{ $t('choose') }}
                        </option> -->
                      <option
                        v-for="opt in tld.vats.list"
                        :key="opt.id"
                        :value="opt.id"
                      >
                        {{ opt.rate }}
                      </option>
                    </template>
                  </b-select-validation>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            $can('create', 'catalog.seo') ||
              $can('update', 'catalog.seo') ||
              $can('read', 'catalog')
          "
          class="column is-12 mt-3"
        >
          <b-tabs
            type="is-boxed"
            class="mb-0 paddingless"
          >
            <b-tab-item
              v-for="(site, i) in sites"
              :key="'tab' + site.id"
              :value="`${site.id}`"
            >
              <template slot="header">
                <img
                  :src="'/img/sites/' + site.id + '.svg'"
                  width="20"
                  height="20"
                  alt
                  class="mr-2"
                >
                {{ site.label }}
              </template>
              <b-tabs
                :class="$device.mobile ? 'paddingless' : 'paddingless-right'"
                :vertical="!$device.mobile"
              >
                <b-tab-item
                  v-for="(lang, j) in site.langs"
                  :key="'lang-' + lang.iso"
                  header-class="mb-4"
                >
                  <template slot="header">
                    <img
                      :src="'/img/langs/' + lang.iso + '.svg'"
                      width="18"
                      height="18"
                      class="mr-2"
                    >
                    {{ lang.label }}
                  </template>
                  <div
                    style="max-width: fit-content;min-width: -webkit-fill-available;"
                  >
                    <div
                      class="columns is-multiline is-mobile ml-2"
                      :class="!$device.mobile ? 'ml-2' : ''"
                    >
                      <div class="column is-6-tablet is-12-mobile">
                        <b-input-validation
                          v-model="seo[i][site.id][j][lang.iso]['name']"
                          :vid="'name' + site.id + lang.iso"
                          :rules="
                            site.id === 1 && lang.iso === 'fr' ? 'required' : ''
                          "
                          :disabled="disableInfo || !$can('create', 'catalog.seo') ||
                            !$can('update', 'catalog.seo') || (action === 'clone' && seo[i][site.id][j][lang.iso]['disabled_name'])"
                          :label="$tc('name', 1)"
                          maxlength="60"
                          :placeholder="placeholders.product.name"
                          @blur="
                            validSeoName(
                              $event,
                              i,
                              site.id,
                              j,
                              lang.iso,
                              seo[i][site.id][j][lang.iso]['disabled_permalink']
                            )
                          "
                        />
                      </div>

                      <div class="column is-6-tablet is-12-mobile">
                        <b-input-validation
                          v-model="seo[i][site.id][j][lang.iso]['permalink']"
                          :vid="'permalink' + site.id + lang.iso"
                          :rules="
                            getPermalinkValidation(
                              site.id,
                              lang.iso,
                              seo[i][site.id][j][lang.iso]['disabled_permalink']
                            )
                          "
                          validation-mode="lazy"
                          :placeholder="placeholders.product.permalink"
                          :disabled="
                            disableInfo ||
                              seo[i][site.id][j][lang.iso]['disabled_permalink']
                              || !$can('create', 'catalog.seo') ||
                              !$can('update', 'catalog.seo')
                          "
                          :label="$t('permalink')"
                          maxlength="60"
                        />
                      </div>

                      <div class="column is-6-tablet is-12-mobile">
                        <b-input-validation
                          v-model="seo[i][site.id][j][lang.iso]['meta_title']"
                          :disabled="disableInfo || !$can('create', 'catalog.seo') ||
                            !$can('update', 'catalog.seo')"
                          :placeholder="placeholders.product.meta_title"
                          :label="
                            $t('meta_title') + ' (' + $t('optional') + ')'
                          "
                          maxlength="90"
                          counter="90"
                        />
                      </div>

                      <div class="column is-6-tablet is-12-mobile">
                        <sortableTagInput
                          v-model="seo[i][site.id][j][lang.iso]['keypoints']"
                          :label="
                            $tc('keypoint', 2) + ' (' + $t('optional') + ')'
                          "
                          ref-value="keypoints_tagInput"
                          :placeholder="
                            seo[i][site.id][j][lang.iso]['keypoints'].length ==
                              0
                              ? placeholders.product.keypoints
                              : ''
                          "
                          :data="[]"
                          :disabled="disableInfo || !$can('create', 'catalog.seo') ||
                            !$can('update', 'catalog.seo')"
                          editable
                          allow-new
                          field="label"
                          sortable
                        />
                      </div>

                      <div class="column is-6-tablet is-12-mobile">
                        <b-input-validation
                          v-model="
                            seo[i][site.id][j][lang.iso]['meta_description']
                          "
                          input-type="textarea"
                          :placeholder="placeholders.product.meta_description"
                          :vid="'meta_description' + site.id + lang.iso"
                          :label="
                            $t('meta_description') + ' (' + $t('optional') + ')'
                          "
                          rows="2"
                          auto-grow
                          :disabled="disableInfo || !$can('create', 'catalog.seo') ||
                            !$can('update', 'catalog.seo')"
                          maxlength="300"
                          counter="300"
                          :suffix="true"
                        >
                          <b-dropdown
                            slot="suffix"
                            position="is-bottom-left"
                            aria-role="list"
                            :disabled="!$can('create', 'catalog.seo') ||
                              !$can('update', 'catalog.seo')"
                          >
                            <template #trigger="{ active }">
                              <b-button
                                :label="$tc('variable', 2)"
                                :icon-right="
                                  active ? 'chevron-up' : 'chevron-down'
                                "
                              />
                            </template>

                            <b-dropdown-item
                              v-for="v in copyVariables.list"
                              :key="v.label"
                              aria-role="listitem"
                              @click="$root.copytoClipboard(v.value)"
                            >
                              {{ v.label }}
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-input-validation>
                      </div>

                      <div class="column is-6-tablet is-12-mobile">
                        <b-input-validation
                          v-model="seo[i][site.id][j][lang.iso]['directions']"
                          input-type="textarea"
                          :placeholder="placeholders.product.directions"
                          :vid="'directions' + site.id + lang.iso"
                          :disabled="disableInfo || !$can('create', 'catalog.seo') ||
                            !$can('update', 'catalog.seo')"
                          :label="
                            $t('directions') + ' (' + $t('optional') + ')'
                          "
                          rows="2"
                          auto-grow
                        />
                      </div>

                      <div class="column is-12">
                        <b-input-validation
                          v-model="seo[i][site.id][j][lang.iso]['description']"
                          input-type="textarea"
                          :placeholder="placeholders.product.description"
                          :vid="'description' + site.id + lang.iso"
                          :disabled="disableInfo || !$can('create', 'catalog.seo') ||
                            !$can('update', 'catalog.seo')"
                          :label="
                            $t('description') + ' (' + $t('optional') + ')'
                          "
                          rows="6"
                          auto-grow
                        />
                      </div>
                      <div class="column is-12 is-size-5">
                        <b>{{ $tc("marketplace", 1) }}</b>
                      </div>

                      <div class="column is-12">
                        <b-input-validation
                          v-model="seo[i][site.id][j][lang.iso]['description_mp']"
                          input-type="textarea"
                          :placeholder="placeholders.product.description"
                          :vid="'description' + site.id + lang.iso"
                          :disabled="disableInfo || !$can('create', 'catalog.seo') ||
                            !$can('update', 'catalog.seo')"
                          :label="
                            $t('description') + ' (' + $t('optional') + ')'
                          "
                          rows="4"
                          maxlength="420"
                          counter="420"
                          has-counter
                          auto-grow
                        />
                      </div>

                      <div class="column is-12">
                        <b-input-validation
                          v-model="seo[i][site.id][j][lang.iso]['description_mp_short']"
                          input-type="textarea"
                          :placeholder="placeholders.product.description"
                          :vid="'description_mp_short' + site.id + lang.iso"
                          :disabled="disableInfo || !$can('create', 'catalog.seo') ||
                            !$can('update', 'catalog.seo')"
                          :label="
                            $t('description_short') + ' (' + $t('optional') + ')'
                          "
                          rows="3"
                          maxlength="200"
                          counter="200"
                          has-counter
                          auto-grow
                        />
                      </div>
                      <div class="column is-12">
                        <b-input-validation
                          v-model="seo[i][site.id][j][lang.iso]['description_mp_long']"
                          input-type="textarea"
                          :placeholder="placeholders.product.description"
                          :vid="'description_mp_long' + site.id + lang.iso"
                          :disabled="disableInfo || !$can('create', 'catalog.seo') ||
                            !$can('update', 'catalog.seo')"
                          :label="
                            $t('description_long') + ' (' + $t('optional') + ')'
                          "
                          rows="6"
                          maxlength="5000"
                          counter="5000"
                          has-counter
                          auto-grow
                        />
                      </div>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </b-tab-item>
          </b-tabs>
        </div>
        <div class="column pt-0">
          <b-button
            type="is-primary"
            :disabled="general_loading || disableInfo"
            :loading="general_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import sortableTagInput from "@/components/forms/elements/sortableTagInput";
export default {
  components: {
    ValidationProvider,
    sortableTagInput
  },
  props: {
    prodId: {
      type: Number,
      required: true
    },
    covers: {
      type: Array,
      default: () => []
    },
    noindex: {
      type: Boolean,
      default: false
    },
    brands: {
      type: Object,
      required: true
    },
    categories: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    tlds: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      disableInfo: false,
      // general models
      supplier: null,
      cover_id: null,
      brand: null,
      keypoints: [],
      categories_selection: [],
      main_category: null,
      noindex_selection: false,

      // general form data
      size_types: [],
      edited_keypoint: {},
      categories_groups: [],
      // Seo
      seo: [],

      // Boolean: if true tlds vats have an initial value
      vats_first_select: false,
      general_loading: false,
      filteredCategories: [],

      suppliers_required: "required",
      tlds_required: "required"
    };
  },
  watch: {
    categories_selection(values) {
      if (values.length == 1) this.main_category = values[0]["id"];
    },
    main_category(id) {
      this.getAttrFromCat(id);
    }
  },
  mounted() {
    if (this.$can("create", "catalog")) {
      this.$emit("setAttribute", "flavor");
    }
  },
  created() {
    if (this.action == "read" || 
    (this.action == "create" && !this.$can("create", "catalog"))||
    (this.action == "edit" && !this.$can("update", "catalog"))) {
      this.disableInfo = true;
    }
    if (
      (this.$can("update", "catalog") || this.$can("read", "catalog")) &&
      this.action !== "create"
    ) {
      if (this.brands.selected) {
        this.brand = this.brands.selected;
      }
      // if (this.suppliers.selected) {
      //   this.supplier = this.suppliers.selected;
      // }
      let cover = this.covers.filter(c => c.selected === true);
      if (cover.length > 0) this.cover_id = cover[0]["id"];
      this.main_category = this.categories.main;
      this.noindex_selection = this.noindex;
      for ( let i = 0; i < this.tlds.length; i++) {
        if (this.tlds[i]["vats"]["selected"]) {
          this.vats_first_select = true;
          this.tlds_required = "";
          break;
        }
      }
    }
    // Selected categories
    let groups = [];
    let selected = [];
    if (this.categories.list !== undefined)
      for (const c of this.categories.list) {
        //groups.push({ header: c.label });
        for (const item of c.children) {
          let elm = {
            ...item,
            attribute: c.attribute
          };
          groups.push({ ...item, attribute: c.attribute });
          if (
            this.categories.selected.length > 0 &&
            this.categories.selected.includes(item.id)
          )
            selected.push(elm);
        }
      }
    this.categories_selection = selected;
    this.categories_groups = groups;
    this.filterCategories("");
    if (this.sites) {
      let arr = [];
      let i;
      for (i = 0; i < this.sites.length; i++) {
        arr.push({ [this.sites[i].id]: [] });
        let j;
        for (j = 0; j < this.sites[i].langs.length; j++) {
          arr[i][this.sites[i].id].push({
            [this.sites[i].langs[j].iso]: {
              name: this.sites[i].langs[j].name || "",
              disabled_name:  this.sites[i].langs[j].name &&
                this.sites[i].langs[j].name.length > 0
                  ? true
                  : false,
              permalink: this.sites[i].langs[j].permalink || "",
              disabled_permalink:
                this.sites[i].langs[j].permalink &&
                this.sites[i].langs[j].permalink.length > 0
                  ? true
                  : false,
              keypoints: this.sites[i].langs[j].keypoints || [],
              meta_title: this.sites[i].langs[j].meta_title || "",
              meta_description: this.sites[i].langs[j].meta_description || "",
              directions: this.sites[i].langs[j].directions || "",
              description: this.sites[i].langs[j].description || null,
              description_mp: this.sites[i].langs[j].description_mp || null,
              description_mp_short: this.sites[i].langs[j].description_mp_short || null,
              description_mp_long: this.sites[i].langs[j].description_mp_long || null,
            }
          });
        }
      }
      this.seo = JSON.parse(JSON.stringify(arr));
    }
  },
  methods: {
    filterCategories(search) {
      this.filteredCategories = this.categories_groups.filter(option => {
        return (
          JSON.stringify(option.label)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });
    },
    getPermalinkValidation(site_id, lang_iso, disabled_permalink) {
      let required = site_id === 1 && lang_iso === "fr" ? "|required" : "";
      let valid_permalink =
        "valid_product_permalink:" + site_id + "/" + lang_iso + "/check";
      return this.action === "create" || !disabled_permalink
        ? valid_permalink + required
        : "";
    },
    selectVat(vat) {
      for ( let i = 0; i < this.tlds.length; i++) {
        if (this.tlds[i].vats.selected) {
          this.tlds_required = "";
          break;
        } else {
          this.tlds_required = "required";
        } 
      }
      // if (this.$can("create", "catalog") || this.$can("update", "catalog")) {
      //   if (!this.vats_first_select) {
      //     this.vats_first_select = true;
      //     for (var i = 0; i < this.tlds.length; i++) {
      //       let same_type = this.tlds[i]["vats"]["list"].filter(
      //         v => v.type === vat.type
      //       );
      //       this.tlds[i]["vats"]["selected"] = same_type[0]["id"];
      //     }
      //   }
      // }
    },
    checkSuppliers() {
      for ( let i = 0; i < this.warehouses.length; i++) {
        if (this.warehouses[i].suppliers.selected) {
          this.suppliers_required = "";
          break;
        } else {
          this.suppliers_required = "required";
        } 
      }
    },
    validSeoName(
      event,
      site_index,
      site_id,
      lang_index,
      lang_iso,
      disabled_permalink
    ) {
      if (
        (this.action === "create" || !disabled_permalink) &&
        this.$can("create", "catalog")
      ) {
        let url =
          "catalog/product/name/" +
          encodeURIComponent(event.target.value.split("/").join(" ")) +
          "/" +
          site_id +
          "/" +
          lang_iso;
        let c =
          this.action === "edit" ? "/check/" + this.prodId + "" : "/check";
        this.$axios
          .get(url + c)
          .then(res => {
            this.$set(
              this.seo[site_index][site_id][lang_index][lang_iso],
              "permalink",
              res.data.permalink
            );
          })
          .catch(e => {
            let permalink =
              e.response && e.response.data && e.response.data.permalink;

            this.$set(
              this.seo[site_index][site_id][lang_index][lang_iso],
              "permalink",
              permalink
            );
          });
      }
    },
    setMainCategory(id) {
      if (this.$can("create", "catalog") || this.$can("update", "catalog"))
        this.main_category = id;
    },
    getAttrFromCat(id) {
      if (this.$can("create", "catalog") || this.$can("update", "catalog")) {
        let category = this.categories_groups.filter(c => c.id === id);
        this.$emit("setAttribute", category[0].attribute);
      }
    },
    removeCategory(id) {
      if (this.$can("create", "catalog") || this.$can("update", "catalog")) {
        if (this.main_category === id) this.main_category = null;
        this.categories_selection = this.categories_selection.filter(
          c => c.id !== id
        );
      }
    },
    save() {
      let cats = this.categories_selection.map(c => c.id);
     
      let vats = this.tlds.map(c => {
          return c.vats.selected ? c.vats.selected :'';
        });

      let filteredVats = vats.filter((str) => str !=='');  
       
      let data = {
        brand_id: this.brand,
        noindex: this.noindex_selection,
        vats: filteredVats,
        categories: cats.filter(c => c != null),
        main_category_id: this.main_category
          ? this.main_category
          : this.categories_selection[0]["id"]
      };

      let seo = [];
      let i, siteLen;
      for (i = 0, siteLen = this.sites.length; i < siteLen; i++) {
        let j;
        let len;
        for (j = 0, len = this.sites[i].langs.length; j < len; j++) {
          seo.push({
            ...this.seo[i][this.sites[i].id][j][this.sites[i].langs[j].iso],
            site_id: this.sites[i].id,
            lang_iso: this.sites[i].langs[j].iso
          });
        }
      }
      data.seo = seo;

      if (this.cover_id) data.cover_id = this.cover_id;

      let url;
      let method;
      if (
        (this.action === "create") &&
        this.$can("create", "catalog")
      ) {
        method = "post";
        url = "catalog/product";
      } else if ((this.action === "edit" || this.action === "clone") && this.$can("update", "catalog")) {
        method = "put";
        url = `catalog/product/${this.prodId}`;
      } else if (this.action === "draft" && this.$can("update", "catalog")) {
        method = "put";
        url = `catalog/product/${this.prodId}`;
      }

      this.general_loading = true;
      this.$axios({
        method: method,
        url: url,
        data: data
      })
        .then(res => {
          if (this.action === "edit" || this.action === "draft") {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          } else if (this.action === "create" || this.action === "clone") {
            this.$root.notify(this.$t("successfully_created"), "is-success");
          }
          this.$emit("changeStep", {
            step: "options",
            prod_id: res.data.prod_id,
            generalSaved: true
          });
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.general_loading = false));
    }
  }
};
</script>
