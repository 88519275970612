<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile is-variable is-1">
        <template v-for="lang in langsObj">
          <ValidationProvider
            v-if="htmlContent"
            :key="lang.iso"
            v-slot="{ errors }"
            tag="div"
            class="column is-6 pr-4"
            :vid="lang.iso"
            :name="lang.label"
            :rules="lang.required ? 'required|max:512' : 'max:512'"
            mode="eager"
          >
            <b-field
              :id="lang.iso"
              :label="lang.required ? lang.label : `${lang.label} (${$t('optional')})`"
              class="editor inline"
              :disabled="disabled"
              :message="errors"
              :type="{
                'is-default': disabled,
                'is-danger': errors[0],
              }"
            >
              <!-- htmlRemoveTags: ['style', 'script'],
                  htmlAllowedStyleProps: [],
                  pasteDeniedAttrs: ['style', 'class', 'id'], -->
              <froala
                v-if="!disabled"
                ref="editor"
                v-model="lang.value"
                :config="editorConfig"
              />
              <froalaView
                v-else
                v-model="lang.value"
              />
            </b-field>
          </ValidationProvider>

          <b-input-validation
            v-else
            :key="lang.iso"
            v-model="lang.value"
            :placeholder="placeholders.nutrifacts[type]"
            input-type="textarea"
            maxlength="512"
            rows="2"
            class="column is-6 pr-4"
            :disabled="disabled"
            :size="size"
            :label="lang.required ? lang.label : `${lang.label} (${$t('optional')})`"
            rules="required"
          />
        </template>
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <div
            v-if="show_new_lang"
            class="column is-12 mb-4 pr-4"
          >
            <div class="columns is-multiline is-mobile is-variable is-2">
              <div class="column is-6 ">
                <b-field
                  :label="$tc('language',1)"
                >
                  <b-autocomplete
                    id="langSearch"
                    ref="taginput"
                    v-model="newLang"
                    type="is-default"
                    expanded
                    autocomplete
                    field="country.label"
                    ellipsis
                    :data="filteredLangs"
                    :placeholder="$t('choose')"
                    :disabled="disabled"
                    @typing="getLangs"
                    @select="option => setNewLang(option)"
                  >
                    <template v-slot="props">
                      {{ props.option.label }}
                    </template>
                  </b-autocomplete>
                </b-field>
              </div>
            </div>
          </div>
        </transition>

        <div
          v-if="!show_new_lang"
          class="column is-12 pt-0 pb-4"
        >
          <a
            href="javascript:;"
            @click="showNewLang()"
          >{{ $t("add_language") }}
          </a>
        </div>
       
        <div class="column pt-2">
          <b-button
            type="is-primary"
            :disabled="loading || disabled"
            :size="size"
            class="mr-2"
            :loading="loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
          <b-button
            :disabled="loading"
            outlined
            :size="size"
            @click="$emit('close')"
          >
            {{ $t("cancel") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FroalaEditor from "froala-editor";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    langs: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "",
    },
    editObj: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      langsObj: [],
      loading: false,
      htmlContent: this.type == "name" ? false : true,
      filteredLangs: [],
      newLang: null,
      show_new_lang: false,
      editorConfig: {
        key: process.env.VUE_APP_FROALA_KEY,
        toolbarInline: true,
        entities: "",
        pastePlain: true,
        htmlSimpleAmpersand: true,
        enter: FroalaEditor.ENTER_BR,
        multiLine: false,
        charCounterMax: 512,
        shortcutsEnabled: ["bold", "italic", "underline"],
        pluginsEnabled: ["entities"],
        toolbarButtons: {
          moreText: {
            buttons: ["bold", "italic", "underline"],
            buttonsVisible: 3,
          },
        },

        events: {
          "html.get": function (html) {
            return html.replace(/id="isPasted"/g, "");
          },
        },
      },
    };
  },
  watch: {
    editObj(newVal) {
      if (newVal.id) {
        this.langsObj = [];
        for (let i = 0; i < this.langs.length; i++) {
          let element = this.langs[i];
          if (element.required) {
            element.value = newVal.langs[element.iso] || "";
            this.langsObj.push(element);
          }
          else if (newVal.langs[element.iso]) {
            element.value = newVal.langs[element.iso];
            this.langsObj.push(element);
          }
        }
      } else {
        this.langsObj = [];
        for (let i = 0; i < this.langs.length; i++) {
          let element = this.langs[i];
          if (element.required) {
            element.value = "";
            this.langsObj.push(element);
          }
        }
      }
      this.$refs.observer.reset();
    },
  },
  created() {
    this.editorConfig.placeholderText = this.placeholders.nutrifacts[this.type];
    for (let i = 0; i < this.langs.length; i++) {
      let element = this.langs[i];
      if (element.required) {
        element.value = "";
        this.langsObj.push(element);
      }
    }
  },
  methods: {
    showNewLang() {
      this.show_new_lang = true;
      setTimeout(() => {
        var element = document.getElementById("langSearch");
        element.select();
      }, 700);
    },
    setNewLang(obj) {
      let element = [];
      element.iso = obj.iso;
      element.label = obj.label;
      element.value = "";
      this.langsObj.push(element);
      setTimeout(() => {
        var field = document.getElementById(obj.iso);
        var input = field.getElementsByClassName("fr-element fr-view")[0];
        input.focus();
      }, 300);
	  },
    getLangs(text) {
		  this.filteredLangs = this.langs;
      this.filteredLangs = this.langs.filter(option => {
			return (
        option.label
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
        );
      });
      var arr = [];
	    for (let i = 0; i< this.langsObj.length; i++) {
		    var lang = { label: this.langsObj[i].label, }
		    arr.push(lang);
					}
        this.filteredLangs = this.filteredLangs.filter(
        l => arr.findIndex(s => s.label == l.label) == -1
      );
    },
    save() {
      let endpoint = "catalog/nutrifacts";
      let method = "POST";
      if (this.editObj.id) {
        endpoint += `/${this.editObj.id}`;
        method = "PUT";
      }

      let langs = [];
      for (let i = 0; i < this.langsObj.length; i++) {
        const lang = this.langsObj[i];
        if(lang.value) langs.push({ iso: lang.iso, value: lang.value });
      }
      let data = {
        type: this.type,
        langs: langs,
      };

      this.loading = true;
      this.$axios({
        method: method,
        url: endpoint,
        data: data,
      })
        .then((res) => {
          if (this.editObj.id) {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
            this.$emit("updated", res.data);
          } else {
            this.$root.notify(this.$t("successfully_created"), "is-success");
            this.$emit("created", res.data);
          }
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
