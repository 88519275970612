<template>
  <ValidationProvider
    v-slot="{ errors }"
    v-on-clickaway="away"
    tag="div"
    :vid="vid"
    :name="$attrs.name || label"
    :rules="rules"
    class="multi-select"
  >
    <b-field
      :label="label"
      :type="{ 'is-danger': errors[0] }"
      :message="errors || message"
    >
      <p
        class="control"
        :class="{ 'is-danger': errors[0], 'is-success': rules && valid }"
        @click="showResult = true"
      >
        <template v-for="(tag, i) in innerValue">
          <b-tag
            :key="i"
            type="is-default"
            class="mr-2"
            :tabstop="false"
            attached
            closable
            @close="removeItem(i)"
          >
            {{ tag[itemText] }}
          </b-tag>
        </template>

        <b-input-validation
          class="hidden"
          :value="innerValue[0] && innerValue[0].id"
        />
        <input
          v-model="search"
          type="text"
          :placeholder="innerValue.length == 0 ? $t('choose') : ''"
        >
      </p>
    </b-field>
    <div
      class="result pt-5 px-4"
      :class="{ active: showResult }"
    >
      <template v-for="item in filteredData">
        <div
          :key="item.id"
          class="columns is-mobile is-gapless mb-0"
        >
          <b-switch
            :value="isChecked(item)"
            class="column is-4 mt-2"
            :disabled="(isChecked({ id: 1 }) && item.id != 1) || disabled"
            @input="checkItem(item)"
          >
            {{ item[itemText] }}
          </b-switch>
          <b-input-validation
            class="column is-8"
            tabindex="1"
            :label="$t('barcode')"
            :placeholder="placeholders.product.barcode"
            :disabled="(isChecked({ id: 1 }) && item.id != 1) || disabled"
            @change="
              value => {
                checkItem(item, value);
              }
            "
          />
        </div>
      </template>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mixin as clickaway } from "vue-clickaway2";

export default {
  components: {
    ValidationProvider
  },
  mixins: [clickaway],
  props: {
    data: {
      type: Array,
      required: true
    },
    value: {
      type: [Array, String, Object],
      required: true
    },
    itemText: {
      type: String,
      required: true
    },
    itemId: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: null
    },
    vid: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showResult: false,
      innerValue: [],
      search: ""
    };
  },
  computed: {
    filteredData() {
      return this.data.filter(option => {
        return (
          JSON.stringify(option[this.itemText])
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) != -1
        );
      });
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    }
  },
  methods: {
    removeItem(index) {
      this.innerValue.splice(index, 1);
    },
    isChecked(item) {
      let index = this.innerValue.findIndex(i => i.id == item.id);
      return index != -1;
    },
    away() {
      this.showResult = false;
    },
    checkItem(item, value) {
      let index = this.innerValue.findIndex(i => i.id == item.id);
      if (index == -1) {
        if (value) item.barcode = value;
        if (item.id == 1) {
          this.innerValue = [item];
        } else {
          this.innerValue.push(item);
        }
      } else {
        if (value) {
          this.innerValue[index].barcode = value;
        } else {
          this.removeItem(index);
        }
      }
    }
  }
};
</script>
