<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile">
        <div
          v-if="
            $can('create', 'catalog.pricing') ||
              $can('update', 'catalog.pricing') ||
              $can('read', 'catalog')
          "
          class="column is-12 mt-3"
        >
          <div class="columns is-mobile is-multiline">
            <div
              v-for="(site, i) in sites"
              :key="'tab-item' + site.id"
              class="column is-3-tablet is-12-mobile"
            >
              <div
                :class="(i + 1) % 2 == 0 ? 'bg-cream' : ''"
                class="columns is-multiline is-mobile is-variable is-2"
              >
                <div class="column is-12">
                  <b>
                    {{ site.label }}
                    {{
                      site.type === "b2b" ? $t("without_vat") : $t("with_vat")
                    }}
                  </b>
                </div>
                <template v-for="(tld, j) in site.tlds">
                  <div
                    :key="'lang-' + tld.id"
                    class="column is-12 pt-0"
                  >
                    <img
                      :src="'/img/flags/' + tld.tld.toUpperCase() + '.svg'"
                      width="18"
                      height="18"
                      class="mr-1"
                    >
                    {{ tld.label }}
                  </div>
                  <div
                    :key="'lang-base' + tld.id"
                    class="column is-6"
                  >
                    <b-input-validation
                      v-model="prices[i][site.id][j][tld.id]['base']"
                      :placeholder="placeholders.price.decimal"
                      step=".01"
                      :rules="
                        `min_value:${prices[i][site.id][j][tld.id]['selling']}`
                      "
                      input-type="number"
                      :vid="'base_' + i + j"
                      :disabled="disablePricing"
                      :label="$t('base')"
                      :suffix="tld['currency']['symbol']"
                    />
                  </div>
                  <div
                    :key="'lang-selling' + tld.id"
                    class="column is-6"
                  >
                    <b-input-validation
                      v-model="prices[i][site.id][j][tld.id]['selling']"
                      :placeholder="placeholders.price.decimal"
                      step=".01"
                      :rules="
                        `max_value:${prices[i][site.id][j][tld.id]['base']}`
                      "
                      input-type="number"
                      :vid="'selling_' + i + j"
                      :disabled="disablePricing"
                      :label="
                        $t('selling') +
                          ' ' +
                          getProfit(
                            tld.id,
                            prices[i][site.id][j][tld.id]['purchase'],
                            prices[i][site.id][j][tld.id]['selling'],
                            tld['currency']['rate'],
                            site.type
                          )
                      "
                      :suffix="tld['currency']['symbol']"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <b-field>
            <p class="control">
              <b-button
                type="is-primary"
                :disabled="pricing_loading || disablePricing"
                :loading="pricing_loading"
                tag="input"
                native-type="submit"
                :value="$t('save')"
              >
                {{ $t("save") }}
              </b-button>
            </p>
            <p
              v-if="$can('update', 'catalog.bulk') && action == 'edit'"
              class="control"
            >
              <b-dropdown position="is-top-right">
                <template #trigger>
                  <b-button
                    type="is-primary"
                    icon-left="chevron-down"
                  />
                </template>

                <b-dropdown-item
                  :disabled="pricing_loading || disablePricing"
                  @click="handleSubmit(() => confirmUpdating('size'))"
                >
                  {{ $tc("all_size", 1) }}
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="pricing_loading || disablePricing"
                  @click="handleSubmit(() => confirmUpdating('color'))"
                >
                  {{ $t("all_color") }}
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="pricing_loading || disablePricing"
                  @click="handleSubmit(() => confirmUpdating('product'))"
                >
                  {{ $t("all_product") }}
                </b-dropdown-item>
              </b-dropdown>
            </p>
          </b-field>
        </div>
      </div>
    </form>
    <b-modal
      v-model="showConfirmUpdate"
      :width="520"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $tc('warehouse', 2) }}</b>
          </div>
          <hr class="my-0">
        </div>
        <div class="columns is-multiline is-mobile">
          <div class="column is-12">
            <div class="columns is-multiline is-mobile">
              <div
                v-for="w in warehouses"
                :key="w.id"
                class="column is-6"
              >
                <b-switch
                  v-model="w.warehouse_bulk"
                >
                  {{ w.country.country }} - {{ w.currency.code }}
                </b-switch>
              </div>
            </div>
          </div>
          
          <div class="column is-12">
            <b-button
              class="mr-4"
              type="is-primary"
              :disabled="pricing_loading"
              :loading="pricing_loading"
              @click="save(true)"
            >
              {{ $t("save") }}
            </b-button>
            <b-button
              :disabled="pricing_loading"
              @click="cancelUpdating"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    prodId: {
      type: Number,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    tlds: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    skus: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      prices: [],
      pricing_loading: false,
      disablePricing: false,
      showConfirmUpdate: false,
      updateType: null,
      warehouse_bulk: [],
      filteredWarehouses: this.warehouses,
    };
  },
  created() {
    if (
      this.$can("create", "catalog") ||
      this.$can("update", "catalog") ||
      this.$can("read", "catalog")
    ) {
      if (this.sites) {
        this.setPrices();
      }
    }
    if (this.action == "read") {
      this.disablePricing = true;
    }
    if (this.action == "edit" && !this.$can('update', 'catalog.pricing')) {
      this.disablePricing = true;
    }
    if (this.action == "create" && !this.$can('create', 'catalog.pricing')) {
      this.disablePricing = true;
    }
    for (let i = 0; i < this.warehouses.length; i++) {
      this.warehouses[i].warehouse_bulk = true;
    }
  },
  methods: {
    confirmUpdating(type) {    
      this.updateType = type;
      if (this.warehouses.length > 1) {
        this.showConfirmUpdate = true;
      }
      else this.save(true);
      // this.$buefy.dialog.confirm({
      //   message: `<b>${this.$t("are_you_sure")}</b>`,
      //   confirmText: this.$t("confirm"),
      //   cancelText: this.$t("cancel"),
      //   type: "is-primary",
      //   onConfirm: () => this.save(type)
      // });
    },
    cancelUpdating() {
      this.updateType = null;
      this.warehouse_bulk = [];
      this.showConfirmUpdate = false;
    },
    setPrices() {
      let arr = [];
      let i;
      for (i = 0; i < this.sites.length; i++) {
        arr.push({ [this.sites[i].id]: [] });
        let j;
        for (j = 0; j < this.sites[i].tlds.length; j++) {
          arr[i][this.sites[i].id].push({
            [this.sites[i].tlds[j].id]: {
              base: this.sites[i].tlds[j].price
                ? this.sites[i].tlds[j].price.base
                : "",
              selling: this.sites[i].tlds[j].price
                ? this.sites[i].tlds[j].price.selling
                : "",
              purchase: this.sites[i].tlds[j].price
                ? this.sites[i].tlds[j].price.purchase
                : ""
            }
          });
        }
      }
      this.prices = JSON.parse(JSON.stringify(arr));
    },
    affectPriceSiteTld(prices, i, j) {
      prices.push({
        ...this.prices[i][this.sites[i].id][j][this.sites[i].tlds[j].id],
        site_id: this.sites[i].id,
        tld_id: this.sites[i].tlds[j].id
      });
    },
    getProfit(tld_id, purchase_price, price, currency_rate, type) {
      let tva;
      let tldObject = this.tlds.filter(t => t.id === tld_id);
      if (tldObject.length > 0) {
        let tld = tldObject[0];
        let tvaObject =
          tld.vats && tld.vats.list.filter(v => v.id === tld.vats.selected);
        if (tvaObject.length > 0) {
          tva = tvaObject[0]["rate"];
          // cast
          tva = Number(tva.replace("%", ""));
        }
      }

      let result;

      // price converted to tld currency
      let price_rate = price / currency_rate;
      if (type === "b2c") {
        result =
          (price_rate / (1 + tva / 100) - purchase_price) /
          (price_rate / (1 + tva / 100));
      } else if (type === "b2b") {
        result = (price_rate - purchase_price) / price_rate;
      } else result = null;

      let percent = (result * 100).toFixed(2);

      if (isNaN(percent)) return "";
      else return "(" + percent + "%)";
    },
    save(isBulk = false) {
      let data = {};
      let prices = [];
      let i;
      for (i = 0; i < this.sites.length; i++) {
        let j;
        for (j = 0; j < this.sites[i].tlds.length; j++) {
          this.affectPriceSiteTld(prices, i, j);
        }
      }
      data.prices = prices;
      // if (bulkType) data.bulk = bulkType;
      if (isBulk) {
        data.bulk = this.updateType;
        data.warehouse_ids = [];
        for (let i = 0; i < this.warehouses.length; i++) {
          if (this.warehouses[i].warehouse_bulk == true) {
            data.warehouse_ids.push(this.warehouses[i].id);
          }
        }
      }
      let method = "PUT";
      let url = "catalog/sku/" + this.skus.join(",");

      this.pricing_loading = true;
      this.$axios({
        method: method,
        url: url,
        data: data
      })
        .then(res => {
          if (this.action === "edit" || this.action == "clone") {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          } else {
            this.$root.notify(this.$t("successfully_created"), "is-success");
          }
          this.$emit("changeStep", {
            step: "images",
            id: this.prodId,
            skus: res.data.skus
          });
          this.showConfirmUpdate = false;
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.pricing_loading = false));
    }
  }
};
</script>
