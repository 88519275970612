<template>
  <div class="wrap">
    <b-table
      :key="headers.length"
      :data="batches"
      hoverable
      mobile-cards
      :total="total"
      paginated
      :per-page="perPage"
      pagination-position="top"
      pagination-size="is-small"
      :current-page="currentPage"
      :loading="batchesloading"
      backend-sorting
      sort-multiple
      :sort-multiple-data="sortingPriority"
      sort-multiple-key="shiftKey"
      :row-class="(row, index) => row.id === added && 'bg-updated'"
      @sort="sortPressed"
      @sorting-priority-removed="sortingPriorityRemoved"
    >
      <template v-for="head in headers">
        <b-table-column
          :key="head.value + '_' + head.display"
          :field="head.value"
          :label="head.label"
          :width="head.width"
          :centered="head.centered"
          :sortable="head.sortable"
          :searchable="head.searchable"
          v-bind="head"
          header-class="sticky-header"
        >
          <template v-if="head.searchable" slot="searchable">
            <template v-if="head.filter_type == 'date'">
              <b-datepicker
                v-model="head.input"
                rounded
                :placeholder="placeholders.date.day"
                size="is-small"
                editable
                :min-date="new Date('01/01/2013')"
                @input="getBatches()"
              />
              <span class="custom-date-filter" @click="showDrop(head.value)">
                <b-icon icon="filter" size="is-small" />
              </span>
              <b-dropdown
                :ref="`filter_drop_${head.value}`"
                aria-role="list"
                class="is-flex"
              >
                <b-dropdown-item
                  aria-role="listitem"
                  @click="changeFilter(head.value, 'is')"
                >
                  {{ $t("is") }}
                </b-dropdown-item>

                <b-dropdown-item
                  aria-role="listitem"
                  @click="changeFilter(head.value, 'greater')"
                >
                  {{ $t("is") }} &gt;
                </b-dropdown-item>

                <b-dropdown-item
                  aria-role="listitem"
                  @click="changeFilter(head.value, 'less')"
                >
                  {{ $t("is") }} &lt;
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-else-if="head.filter_type == 'select'">
              <b-select-validation
                v-model="head.input"
                rounded
                size="is-small"
                :expanded="true"
                @change="getBatches()"
              >
                <template v-if="head.value === 'warehouse_id'">
                  <option
                    v-for="opt in warehouses"
                    :key="opt.id"
                    :value="opt.id"
                  >
                    {{ opt.country.country + " - " + opt.currency.code }}
                  </option>
                </template>
                <template v-else>
                  <option
                    v-for="opt in head.filter_values"
                    :key="head.filter_id ? opt[head.filter_id] : opt"
                    :value="head.filter_id ? opt[head.filter_id] : opt"
                  >
                    {{ head.filter_text ? opt[head.filter_text] : opt }}
                  </option>
                </template>
              </b-select-validation>
            </template>
            <template v-else-if="head.filter_type == 'boolean'">
              <b-select-validation
                v-model="head.input"
                rounded
                expanded
                size="is-small"
                @change="getBatches()"
              >
                <template>
                  <option :value="true">
                    {{ $t("yes") }}
                  </option>
                  <option :value="false">
                    {{ $t("no") }}
                  </option>
                </template>
              </b-select-validation>
            </template>
            <template v-else>
              <b-input
                v-model="head.input"
                :placeholder="
                  head.value == 'size'
                    ? getSizeLabel(head)
                    : $root.getFilterPlaceholder(head)
                "
                rounded
                size="is-small"
                icon-right="filter"
                icon-right-clickable
                @icon-right-click="showDrop(head.value)"
                @input="getBatches"
              />
              <b-dropdown
                :ref="`filter_drop_${head.value}`"
                aria-role="list"
                class="is-flex"
              >
                <template v-if="head.value == 'size'">
                  <b-dropdown-item
                    v-for="item in sizes_types"
                    :key="item.id"
                    aria-role="listitem"
                    @click="changeFilter(head.value, item.id)"
                  >
                    {{ item.label }}
                  </b-dropdown-item>
                </template>
                <template v-else>
                  <template v-if="head.filter_type == 'numeric'">
                    <b-dropdown-item
                      v-for="filter in filtersData.numeric"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      v-for="filter in filtersData.char"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>
                </template>
              </b-dropdown>
            </template>
          </template>

          <!-- Warehouse -->
          <template v-if="head.value === 'warehouse_id'" v-slot="props">
            <img
              height="18"
              width="18"
              :src="'/img/flags/' + props.row.warehouse.country.iso + '.svg'"
            />
          </template>

          <!-- id -->
          <template v-else-if="head.value == 'stock_id'" v-slot="props">
            <a
              v-if="$can('update', 'purchasing')"
              href="javascript:;"
              @click="
                openBatchDrawer(
                  props.row.id,
                  props.row.stock,
                  props.row.purchase_price.value,
                  props.row.expire_at,
                  props.row.internal,
                  false,
                  props.row.warehouse.id,
                  warehouses,
                  props.row.sold
                )
              "
              >{{ props.row.id }}</a
            >
            <span v-else>{{ props.row.id }}</span>
          </template>

          <!-- stock -->
          <template v-else-if="head.value == 'stock'" v-slot="props">
            {{ props.row.stock }}
          </template>

          <!-- purchase_price -->
          <template v-else-if="head.value == 'purchase_price'" v-slot="props">
            {{
              props.row.purchase_price
                ? props.row.purchase_price.formatted
                : "-"
            }}
          </template>

          <!-- expire_at -->
          <template v-else-if="head.value == 'expire_at'" v-slot="props">
            {{ props.row.expire_at ? props.row.expire_at.formatted : "-" }}
          </template>

          <template v-else v-slot="props">
            <b-icon
              v-if="typeof props.row[head.value] === 'boolean'"
              :icon="props.row[head.value] ? 'check' : 'close'"
              :type="props.row[head.value] ? 'is-success' : 'is-danger'"
              custom-size="mdi-18px"
            />
            <template v-else>
              {{ showValue(props.row[head.value]) }}
            </template>
          </template>
        </b-table-column>
      </template>
      <EmptyBlock slot="empty" icon="file-powerpoint-box" />
    </b-table>
    <!-- <b-table
      :data="batches"
      paginated
      :per-page="perPage"
      pagination-position="top"
      pagination-size="is-small"
      :current-page="currentPage"
      :default-sort-direction="defaultSortDirection"
      :loading="batchesloading"
      default-sort="stock"
      :row-class="(row, index) => row.id === added && 'bg-updated'"
    >
      <b-table-column
        v-slot="props"
        field="id"
        :label="$t('id')"
        sortable
        width="20"
      >
        <a      
          v-if="$can('update', 'purchasing')"
          href="javascript:;"
          @click="openBatchDrawer(props.row.id, props.row.stock, props.row.purchase_price.value, props.row.expire_at, props.row.internal, false)"
        >{{ props.row.id }}</a>
        <span v-else>{{ props.row.id }}</span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="stock"
        :label="$t('stock')"
        cell-class="editable"
        sortable
        width="80"
      >
        {{ props.row.stock }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="purchase_price"
        :label="$t('purchase_price')"
        cell-class="editable"
        sortable
        width="80"
      >
        {{
          props.row.purchase_price ? props.row.purchase_price.formatted : "-"
        }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="expire_at"
        :label="$t('expire_at')"
        sortable
        width="80"
      >
        {{ props.row.expire_at ? props.row.expire_at.formatted : "-" }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="warehouse"
        :label="$tc('warehouse',1)"
        searchable
        sortable
        width="80"
      >
        <template
          slot="searchable"
        >
          <template>
            <b-select-validation
              v-model="warehouse_id"
              rounded
              size="is-small"
              :expanded="true"
              @change="getBatches()"
            >
              <template>
                <option
                  v-for="opt in warehouses"
                  :key="opt.id"
                  :value="opt.id"
                >
                  {{ opt.country.country }}
                </option>
              </template>
            </b-select-validation>
          </template>
        </template>
        
        {{ props.row.warehouse? props.row.warehouse : "-" }}
      </b-table-column>
      
      <b-table-column
        v-slot="props"
        field="internal"
        :label="$tc("internal_batch", 1)"
        sortable
        centered
        width="80"
      >      
        <b-icon
          :icon=" props.row.internal ? 'check' : 'close'"
          :type="props.row.internal ? 'is-success' : 'is-danger'"
          size="is-small"
        />
      </b-table-column>
      <EmptyBlock
        slot="empty"
        icon="currency-usd-off"
      />
    </b-table> -->
    <b-button
      v-if="$can('create', 'purchasing')"
      size="is-small"
      type="is-primary"
      outlined
      class="mt-2 ml-2"
      @click="
        openBatchDrawer(null, null, null, null, false, true, null, warehouses)
      "
    >
      {{ $t("add_batch") }}
    </b-button>
    <Pagination
      :per-page="perPage"
      :per-pages="rowsPerPage"
      :total="batches.length"
      :current-page="currentPage"
      @update-per-page="(val) => (perPage = val)"
      @update-current-page="(val) => (currentPage = val)"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    batches: {
      type: Array,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    batchesloading: {
      type: Boolean,
      default: false,
    },
    warehouses: {
      type: Array,
      required: true,
    },
    skus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      noDataText: this.$i18n.t("no_result_found"),
      rowsPerPage: [10, 50, 100],
      perPage: 10,
      currentPage: 1,
      pagination: {},
      sortingPriority: [{ field: "stock", order: "desc" }],
      singleSorting: {},
      items: this.batches,
      editPrice: null,
      price: null,
      editStock: null,
      stock: null,
      editDatetime: null,
      datetime: null,
      modal: false,
      newPrice: null,
      newStock: null,
      internalUse: false,
      newDatetime: null,
      // defaultSortDirection: 'desc',
      added: null,
      warehouse_id: null,
      headers: [
        {
          label: this.$t("id"),
          value: "stock_id",
          sortable: true,
          searchable: true,
          width: "50",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: false,
        },
        {
          label: this.$tc("warehouse", 1),
          value: "warehouse_id",
          width: "80",
          sortable: true,
          searchable: true,
          filter_type: "select",
          filter_text: "country",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("stock"),
          value: "stock",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("purchase_price"),
          value: "purchase_price",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("expire_at"),
          value: "expire_at",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "date",
          input: null,
          filter: "is",
          display: false,
        },
        {
          label: this.$tc("internal_batch", 1),
          value: "internal",
          sortable: true,
          searchable: true,
          width: "50",
          filter_type: "boolean",
          input: "",
          display: false,
        },
      ],
    };
  },
  mounted() {
    this.$bus.$on("add-new-batch", (params) => {
      this.addNewBatch(
        params.newPrice,
        params.newStock,
        params.newDatetime,
        params.internalUse,
        params.fromOrder,
        params.warehouse_id
      );
    });
    this.$bus.$on("update-batch", (params) => {
      this.updateBatch(
        params.id,
        params.newPrice,
        params.newStock,
        params.newDatetime,
        params.internalUse,
        params.fromOrder,
        params.warehouse_id
      );
    });
  },
  methods: {
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.getBatches();
    },
    openBatchDrawer(
      id,
      stock,
      purchase_price,
      datetime,
      internal,
      add,
      warehouse,
      warehouses,
      sold
    ) {
      let date =
        datetime && datetime.datetime
          ? datetime.datetime.substring(0, 10)
          : null;
      let expire_at = datetime ? new Date(date) : null;
      let options = {
        level: 1,
        id: id,
        stock: stock,
        purchase_price: purchase_price,
        expire_at: expire_at,
        internal: internal,
        currency: this.currency,
        add: add,
        width: "small",
        fromOrder: false,
        warehouse: warehouse,
        warehouses: warehouses,
        sold: sold,
      };
      this.openDrawer("editBatch", options);
    },
    addNewBatch(price, stock, date, internalUse, fromOrder, warehouse_id) {
      if (!fromOrder) {
        this.loading = true;
        let url = "catalog/sku/" + this.skus[0] + "/batches";
        this.$axios
          .post(url, {
            prod_ref_id:
              this.action == "clone"
                ? this.skus[0]
                : parseInt(this.$route.params.sku),
            purchase_price: parseFloat(price),
            stock: parseInt(stock),
            internal: Number(internalUse),
            expire_at: moment(date).add(1, "d").toISOString(),
            warehouse_id: warehouse_id,
          })
          .then((res) => {
            this.added = res.data.id;
            setTimeout(() => {
              this.added = null;
            }, 1000);
            let newBatch = {
              id: res.data.id,
              stock: res.data.stock,
              purchase_price: res.data.purchase_price,
              expire_at: res.data.expire_at,
              internal: res.data.internal,
              warehouse: res.data.warehouse,
            };
            this.batches.push(newBatch);
            this.closeDrawer(1);
            this.$root.notify(this.$t("successfully_created"), "is-success");
          })
          .catch((e) => this.$root.clientError(e))
          .finally(() => (this.loading = false));
      }
    },
    updateBatch(id, price, stock, date, internalUse, fromOrder, warehouse_id) {
      if (!fromOrder) {
        // let data = {};
        let url = "catalog/sku/" + this.$route.params.sku + "/batches/" + id;
        // if (property === 'internal') data[property] = e;
        // else data[property] = e.target && e.target.value && parseFloat(e.target.value);
        this.$axios
          .put(url, {
            prod_ref_id: parseInt(this.$route.params.sku),
            purchase_price: parseFloat(price),
            stock: parseInt(stock),
            internal: Number(internalUse),
            expire_at: moment(date).add(1, "d").toISOString(),
            warehouse_id: warehouse_id,
          })
          .then((res) => {
            this.items = this.batches;
            let index = this.items.findIndex((x) => x.id == id);
            this.$set(this.items, index, res.data);
            this.added = res.data.id;
            setTimeout(() => {
              this.added = null;
            }, 1000);
            this.editPrice = null;
            this.editStock = null;
            this.price = null;
            this.stock = null;
            this.closeDrawer(1);
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          })
          .catch((e) => this.$root.clientError(e));
      }
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = [];
        this.singleSorting = { field, order };
      }
      this.getBatches();
    },
    sortingPriorityRemoved(field) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field
      );
      this.getBatches();
    },
    getBatches() {
      let api_params = "";
      let searchArr = [];
      let filtering = false;
      for (let i = 0; i < this.headers.length; i++) {
        let obj = this.headers[i];
        let key = obj.value;
        let value = obj;
        if (value.input || typeof value.input == "boolean") {
          let obj = {
            id: key,
            selected:
              ["expire_at"].indexOf(key) != -1
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }

      if (searchArr.length) {
        api_params = `?filters=${btoa(JSON.stringify(searchArr))}`;
        filtering = true;
      }

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += filtering ? "&" : "?";
        api_params += `sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += filtering ? "&" : "?";
        api_params += `sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      this.$emit("getData", {
        params: api_params,
      });
    },
    // update(e, index, item, property) {
    //   let data = {};
    //   let url = "catalog/sku/" + this.$route.params.sku + "/batches/" + item.id;
    //   if (property === 'internal') data[property] = e;
    //   else data[property] = e.target && e.target.value && parseFloat(e.target.value);
    //   this.$axios
    //     .put(url, data)
    //     .then(res => {
    //       this.items = this.batches;
    //       let id = this.items.findIndex(x => x.id == item.id);
    //       this.$set(this.items, id, res.data);
    //       this.editPrice = null;
    //       this.editStock = null;
    //       this.price = null;
    //       this.stock = null;
    //       this.$root.notify(this.$t("successfully_updated"), "is-success");
    //     })
    //     .catch(e => this.$root.clientError(e));
    // },
    // updateDate() {
    //   this.loading = true;
    //   let url = "catalog/sku/" + this.$route.params.sku + "/batches/" + this.editDatetime ;
    //   this.$axios
    //       .put(url, {
    //       expire_at: moment(this.datetime)
    //         .add(1, "d")
    //         .toISOString()
    //     })
    //     .then(res => {
    //       this.items = this.batches;
    //       let index = this.items.findIndex(obj => obj.id === this.editDatetime);
    //       this.$set(this.items, index, res.data);
    //       this.editDatetime = null;
    //       this.datetime = null;
    //       this.modal = false;
    //       this.$root.notify(this.$t("successfully_updated"), "is-success");
    //     })
    //     .catch(e => this.$root.clientError(e))
    //     .finally(() => (this.loading = false));
    // }
  },
};
</script>
