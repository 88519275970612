var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.$can('create', 'catalog') ||
      _vm.$can('update', 'catalog') ||
      _vm.$can('read', 'catalog')) &&
      !_vm.notFound
  )?_c('Page',{attrs:{"subtitle":_vm.subtitle,"loading":_vm.loading,"has-actions":""}},[(_vm.product.options)?_c('b-switch',{attrs:{"slot":"actions","disabled":(_vm.product.options.active && !this.$can('delete', 'catalog')) ||
        (!_vm.product.options.active && !this.$can('update', 'catalog')) ||
        _vm.action == 'draft',"value":_vm.product.options.active,"type":"is-success"},on:{"input":_vm.activateOrDesactivateProduct},slot:"actions"},[_vm._v(" "+_vm._s(_vm.$tc("active", 1))+" ")]):_vm._e(),_c('Card',{attrs:{"content-class":"px-0 py-1"}},[(_vm.loading)?_c('div',{staticStyle:{"min-height":"200px"}},[_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1):_c('b-tabs',{class:_vm.activeTab == 'analytics' ? 'paddingless' : '',attrs:{"expanded":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":_vm.$t('general'),"disabled":_vm.generalDisabled,"value":"general"}},[_c('General',{attrs:{"prod-id":_vm.prod_id,"suppliers":_vm.product.suppliers,"brands":_vm.product.brands,"covers":_vm.product.covers,"warehouses":_vm.product.filters.warehouses,"noindex":_vm.product.options && _vm.product.options.noindex,"categories":_vm.product.categories,"sites":_vm.product.sites,"tlds":_vm.product.tlds,"action":_vm.action},on:{"setAttribute":_vm.setProductAttribute,"changeStep":_vm.changeStep}})],1),_c('b-tab-item',{attrs:{"disabled":_vm.optionsDisabled,"label":_vm.$t('options'),"value":"options"}},[_c('Options',{key:_vm.attribute,attrs:{"prod-id":_vm.prod_id,"sizes-types":_vm.product.sizes_types,"colors":_vm.product.colors,"flavors":_vm.product.flavors,"sold":_vm.product.sold &&
              _vm.action != 'clone' &&
              !_vm.$can('update', 'catalog.override'),"attributes-props":_vm.product.attributes || {},"langs":_vm.filters.languages,"attributes-types":_vm.filters.types,"warehouses":_vm.product.filters.warehouses,"car-categories":_vm.product.filters.car_categories,"attribute":_vm.attribute,"action":_vm.action,"sites":_vm.product.sites,"tlds":_vm.product.tlds,"options":_vm.product.options,"countries":_vm.product.countries},on:{"changeStep":_vm.changeStep}})],1),_c('b-tab-item',{attrs:{"disabled":_vm.PricingDisabled,"label":_vm.$t('pricing'),"value":"pricing"}},[_c('Pricing',{key:_vm.attribute,attrs:{"prod-id":_vm.prod_id,"sites":_vm.product.sites,"tlds":_vm.product.tlds,"skus":_vm.skus,"action":_vm.action,"warehouses":_vm.product.filters.warehouses},on:{"changeStep":_vm.changeStep}})],1),_c('b-tab-item',{attrs:{"disabled":_vm.imagesDisabled,"label":_vm.$t('images'),"value":"images"}},[_c('Images',{attrs:{"skus":_vm.skus,"images":_vm.images,"action":_vm.action,"warehouses":_vm.product.filters.warehouses},on:{"changeStep":_vm.changeStep}})],1),(_vm.action == 'edit' || _vm.action == 'read' || _vm.action == 'clone')?_c('b-tab-item',{attrs:{"disabled":_vm.BatchesDisabled,"label":_vm.$tc('batch', 2),"value":"batches"}},[_c('Batches',{attrs:{"batches":_vm.product_batches,"action":_vm.action,"colors":_vm.product.colors,"currency":_vm.currency,"warehouses":_vm.product.filters.warehouses,"batchesloading":_vm.batches_loading,"skus":_vm.skus},on:{"getData":_vm.getBatches}})],1):_vm._e(),(
          (_vm.action == 'edit' || _vm.action == 'read') &&
            _vm.$can('read', 'orders.analytics')
        )?_c('b-tab-item',{attrs:{"label":_vm.$tc('analytic', 2),"value":"analytics"}},[(_vm.activeTab == 'analytics')?_c('ranking-view',{staticClass:"is-shadowless",attrs:{"total":_vm.total,"pagination-object":_vm.pagination,"stats":_vm.statsAnalytics,"stats-loading":_vm.stats_loading,"headers":_vm.stats_headers,"warehouses":_vm.warehousesAnalytics,"origin-sites":_vm.originSites,"brands":_vm.brands,"salesmen":_vm.salesmenAnalytics,"origin-categories":_vm.originCategories,"original-data":_vm.originalData,"prod-id":_vm.prod_id},on:{"getData":_vm.loadAnalyticsData}}):_vm._e(),_c('hr',{staticClass:"has-background-grey-lighter"}),(_vm.activeTab == 'analytics')?_c('graphs-component',{staticClass:"is-shadowless",attrs:{"stats":_vm.data,"periods":_vm.periods,"brands-prop":_vm.graphBrands,"origin-categories":_vm.categories,"warehouses":_vm.warehouses,"origin-sites":_vm.sites,"salesmen":_vm.salesmen,"loading-prop":_vm.loadingGraph},on:{"getData":_vm.getData}}):_vm._e()],1):_vm._e(),(
          _vm.action != 'create' &&
            _vm.action != 'draft' &&
            _vm.nutritionalValues &&
            (_vm.$can('read', 'catalog.nutrifacts.products') ||
              _vm.$can('create', 'catalog.nutrifacts.products') ||
              _vm.$can('update', 'catalog.nutrifacts.products'))
        )?_c('b-tab-item',{attrs:{"disabled":_vm.nutriFactsDisabled,"label":_vm.$t('nutritional_values'),"value":"nutritional_values"}},[(_vm.activeTab == 'nutritional_values')?_c('Nutrifacts',{attrs:{"data":_vm.nutritionalValues,"langs":_vm.filters.languages,"units":_vm.filters.units,"action":_vm.action,"skus":_vm.skus},on:{"refreshNutrifacts":_vm.refreshNutrifacts}}):_vm._e()],1):_vm._e()],1)],1)],1):(_vm.notFound)?_c('NotFoundPage'):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }