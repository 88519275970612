<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile is-variable is-2">
        <b-select-validation
          v-model="unit_id"
          class="column"
          :class="type == 'micro' || type == 'macro' ? 'is-3' : 'is-12'"
          :size="size"
          rules="required"
          :disabled="disabled"
          validation-mode="lazy"
          :label="$t('unit')"
          vid="unit"
        >
          <option
            v-for="unit in units"
            :key="unit.id"
            :value="unit.id"
          >
            {{
              unit.label
            }}
          </option>
        </b-select-validation>
        <b-input-validation
          v-if="type == 'micro' || type == 'macro'"
          v-model="nrv"
          :size="size"
          class="column"
          step=".01"
          :class="type == 'micro' || type == 'macro' ? 'is-3' : 'is-6'"
          input-type="number"
          :label="`${$t('nrv')} (${$t('optional')})`"
          :disabled="disabled"
          :placeholder="placeholders.nutrifacts.nrv"
          vid="nrv"
        />
        <b-autocomplete-validation
          v-if="type == 'micro' || type == 'macro'"
          :key="`parent-${parent_id}`"
          v-model="parent_id"
          class="column is-6"
          :label="`${$t('parent')} (${$t('optional')})`"
          :size="size"
          :data="type == 'micro' ? micro : macro"
          :placeholder="placeholders.nutrifacts[type]"
          :disabled="disabled"
          item-text="label"
          field="id"
        />
        <div
          class="is-size-6 column is-12 pb-3"
        >
          <b>{{ $tc('translation', 2) }}</b>
        </div>
    
        <template v-for="lang in langsObj">
          <b-input-validation
            :id="lang.iso"
            :key="lang.iso"
            v-model="lang.value"
            :placeholder="placeholders.nutrifacts[type]"
            class="column is-6"
            :size="size"
            :label="lang.required ? lang.label : `${lang.label} (${$t('optional')})`"
            maxlength="512"
            :disabled="disabled"
            :rules="lang.required ? 'required' : ''"
            validation-mode="lazy"
          />
        </template>
        
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <div
            v-if="show_new_lang"          
            class="column is-12 mb-4"
          >
            <div class="columns is-multiline is-mobile is-variable is-2">
              <div class="column is-6 ">
                <b-field
                  :label="$tc('language',1)"
                >
                  <b-autocomplete
                    id="langSearch"
                    ref="taginput"
                    v-model="newLang"
                    type="is-default"
                    expanded
                    autocomplete
                    field="country.label"
                    ellipsis
                    :data="filteredLangs"
                    :placeholder="$t('choose')"
                    :disabled="disabled"
                    @typing="getLangs"
                    @select="option => setNewLang(option)"
                  >
                    <template v-slot="props">
                      {{ props.option.label }}
                    </template>
                  </b-autocomplete>
                </b-field>
              </div>
            </div>
          </div>
        </transition>
        <!-- <transition
          name="slide-fade"
          mode="out-in"
        > -->
        <div
          v-if="!show_new_lang"
          class="column is-12 mb-4"
        >
          <a
            href="javascript:;"
            @click="showNewLang()"
          >{{ $t("add_language") }}
          </a>
        </div>
        <!-- </transition> -->
        <div class="column is-12">
          <b-button
            type="is-primary"
            :size="size"
            class="mr-4"
            :loading="loading"
            tag="input"
            native-type="submit"
            :disabled="disabled || loading || (!editObj.id && type == 'macro')"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
          <b-button
            :disabled="loading"
            outlined
            :size="size"
            @click="$emit('close')"
          >
            {{ $t("cancel") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    units: {
      type: Array,
      required: true
    },
    langs: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    micro: {
      type: Array,
      default: () => []
    },
    macro: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: ""
    },
    editObj: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unit_id: null,
      nrv: null,
      parent_id: null,
      langsObj: [],
      filteredLangs: [],
      newLang: null,
      show_new_lang: false,
      loading: false
    };
  },
  watch: {
    editObj(newVal) {
      if (newVal.id) {
        let unit = this.units.find(u => u.label == newVal.unit);
        this.unit_id = unit && unit.id;
        this.nrv = newVal.nrv && newVal.nrv.value;
        this.parent_id = newVal.parent_id;
        this.langsObj = [];
        for (let i = 0; i < this.langs.length; i++) {
          let element = this.langs[i];
          if (element.required) {
            element.value = newVal.langs[element.iso] || "";
            this.langsObj.push(element);
          }
          else if (newVal.langs[element.iso]) {
            element.value = newVal.langs[element.iso];
            this.langsObj.push(element);
          }
        }
      } else {
        this.unit_id = "";
        this.nrv = null;
        this.parent_id = null;
        this.langsObj = [];
        for (let i = 0; i < this.langs.length; i++) {
          let element = this.langs[i];
          if (element.required) {
            element.value = "";
            this.langsObj.push(element);
          }
        }
      }
      this.$refs.observer.reset();
    }
  },
  created() {
    for (let i = 0; i < this.langs.length; i++) {
      let element = this.langs[i];
      if (element.required) {
        element.value = "";
        this.langsObj.push(element);
      }
    }
    
  },
  methods: {
    showNewLang() {
      this.show_new_lang = true;     
      setTimeout(() => {
        var element = document.getElementById("langSearch");
        element.select();
      }, 700);
      
    },
    setNewLang(obj) {
      let element = [];
      element.iso = obj.iso;
      element.label = obj.label;
      element.value = "";
      this.langsObj.push(element);
      setTimeout(() => {
        var field = document.getElementById(obj.iso);
        var input = field.getElementsByTagName("input")[0]
        input.focus();
      }, 300);
	  },
    getLangs(text) {
		  this.filteredLangs = this.langs;
      this.filteredLangs = this.langs.filter(option => {
			return (
        option.label
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
        );
      });
      var arr = [];
	    for (let i = 0; i< this.langsObj.length; i++) {
		    var lang = { label: this.langsObj[i].label, }
		    arr.push(lang);
					}
        this.filteredLangs = this.filteredLangs.filter(
        l => arr.findIndex(s => s.label == l.label) == -1
      );
    },
    save() {
      let endpoint = "catalog/nutrifacts";
      let method = "POST";
      if (this.editObj.id) {
        endpoint += `/${this.editObj.id}`;
        method = "PUT";
      }

      let data = {
        type: this.type,
        nrv: this.nrv,
        unit_id: this.unit_id
      };

      if (this.parent_id) {
        data.parent_id = this.parent_id;
      }

      let langs = [];
      for (let i = 0; i < this.langsObj.length; i++) {
        const lang = this.langsObj[i];
        if(lang.value) langs.push({ iso: lang.iso, value: lang.value });
      }
      data.langs = langs;

      this.loading = true;
      this.$axios({
        method: method,
        url: endpoint,
        data: data
      })
        .then(res => {
          if (this.editObj.id) {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
            this.$emit("updated", res.data);
          } else {
            this.$root.notify(this.$t("successfully_created"), "is-success");
            this.$emit("created", res.data);
          }
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
