<template>
  <div class="wrap">
    <form
      method="post"
      @submit.prevent="save"
    >
      <ImageUpload
        :allow-multiple="allowMultiple"
        :accepted-file-types="acceptedFileTypes"
        :images="[]"
        @updateFiles="onUpdateFiles"
      />
      <b-field v-if="myFiles.length">
        <p class="control">
          <b-button
            type="is-primary"
            :loading="loading"
            :disabled="loading || action == 'read'"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </p>
        <p
          v-if="$can('update', 'catalog.bulk') && action == 'edit'"
          class="control"
        >
          <b-dropdown position="is-top-right">
            <template #trigger>
              <b-button
                type="is-primary"
                icon-left="chevron-down"
              />
            </template>

            <b-dropdown-item
              :disabled="loading || action == 'read'"
              @click="confirmUpdating('size')"
            >
              {{ $tc("all_size", 1) }}
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="loading || action == 'read'"
              @click="confirmUpdating('color')"
            >
              {{ $t("all_color") }}
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="loading || action == 'read'"
              @click="confirmUpdating('product')"
            >
              {{ $t("all_product") }}
            </b-dropdown-item>
          </b-dropdown>
        </p>
      </b-field>

      <div class="is-flex is-flex-wrap-wrap is-justify-content-space-between">
        <div
          v-for="(img, i) in images"
          :key="i"
          class="upload-img-container has-border mb-2"
        >
          <p>{{ img.filename }}</p>
          <img
            width="600"
            :src="getImgURL(img)"
          >

          <span
            class="delete-icon"
            @click="confirmDeleting(img, i)"
          >
            <b-icon
              icon="close"
              size="is-small"
            />
          </span>
        </div>
      </div>
    </form>
    <b-modal
      v-model="showConfirmUpdate"
      :width="520"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $tc('warehouse', 2) }}</b>
          </div>
          <hr class="my-0">
        </div>
        <div class="columns is-multiline is-mobile">
          <div class="column is-12">
            <div class="columns is-multiline is-mobile">
              <div
                v-for="w in warehouses"
                :key="w.id"
                class="column is-6"
              >
                <b-switch
                  v-model="w.warehouse_bulk"
                >
                  {{ w.country.country }} - {{ w.currency.code }}
                </b-switch>
              </div>
            </div>
          </div>
          
          <div class="column is-12">
            <b-button
              class="mr-4"
              type="is-primary"
              :loading="loading"
              :disabled="loading"
              @click="save(true)"
            >
              {{ $t("save") }}
            </b-button>
            <b-button
              :disabled="loading"
              @click="cancelUpdating"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import ImageUpload from "@/components/forms/elements/ImageUpload.vue";

export default {
  components: {
    ImageUpload
  },
  props: {
    acceptedFileTypes: {
      type: String,
      default: "image/png"
    },
    allowMultiple: {
      type: Boolean,
      default: true
    },
    action: {
      type: String,
      required: true
    },
    skus: {
      type: Array,
      required: true
    },
    images: {
      type: Array,
      default: () => []
    },
    warehouses: {
      type: Array,
      required: true
    },
  },
  data: function() {
    return {
      myFiles: [],
      loading: false,
      showConfirmUpdate: false,
      updateType: null,
      warehouse_bulk: [],
      filteredWarehouses: this.warehouses,
    };
  },

  created() {
    for (let i = 0; i < this.warehouses.length; i++) {
      this.warehouses[i].warehouse_bulk = true;
    }
  },

  methods: {
    confirmUpdating(type) {
      this.updateType = type;
      if (this.warehouses.length > 1) {
        this.showConfirmUpdate = true;
      }
      else this.save(true);  
    },
    cancelUpdating() {
      this.updateType = null;
      this.warehouse_bulk = [];
      this.showConfirmUpdate = false;
    },
    getImgURL(Img) {
      return `${Img.url}${Img.path}${Img.filename}-w600h600@2x.${Img.version}.jpg`;
    },
    deleteImg(img, index) {
      this.loading = true;
      let url = `catalog/images/${this.skus.join(",")}/${img.filename}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
          this.images.splice(index, 1);
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    },
    confirmDeleting(img, index) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteImg(img, index)
      });
    },
    b64toBlob(dataURI, type) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: type });
    },
    onUpdateFiles(files) {
      this.myFiles = files;
    },
    save(bulk = null) {
      this.loading = true;
      const formData = new FormData();

      if (this.updateType) { 
        formData.append("bulk", this.updateType);
        for (let i = 0; i < this.warehouses.length; i++) {
          if (this.warehouses[i].warehouse_bulk == true) {
            formData.set("warehouse_ids[" + i + "]", this.warehouses[i].id);
          }
        }

        // for (var j = 0; j < this.warehouses.length; j++) {
        //   if(warehouses[j]) formData.set("warehouses[" + j + "]", warehouses[j]);
        // }
        // formData.append("warehouses", this.warehouse_bulk.map(w => w.id));
      }
      for (var i = 0; i < this.myFiles.length; i++) {
        let file = this.myFiles[i];
        formData.append("files[" + i + "]", file);
      }

      for (var j = 0; j < this.skus.length; j++) {
        formData.set("ids[" + j + "]", this.skus[j]);
      }
      let url =
        this.action === "edit_product"
          ? "catalog/images/update"
          : "catalog/images";
      this.$axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "content-type": "multipart/form-data" }
      })
        .then(() => {
          this.showConfirmUpdate = false;
          this.updateType = null;
          this.$root.notify(this.$t("successfully_updated"), "is-success");
          this.$emit("changeStep", { step: "batches" });
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
